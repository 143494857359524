import { useConversationUtilities } from "@/components/roomPage/tabs/conversation/ConversationLists/useConversationUtilities";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { type ConversationWithDM } from "@/interfaces/conversation";
import { useMemo, type FunctionComponent } from "react";
interface IDMOptionComponentProps {
  conversation: ConversationWithDM;
}
export const DMOptionComponent: FunctionComponent<IDMOptionComponentProps> = ({ conversation }) => {
  const { data: me } = useMeQuery();
  const { computeConversationTitleFromMemberList } = useConversationUtilities();

  const name = useMemo(() => {
    return computeConversationTitleFromMemberList(conversation.members);
  }, [conversation]);
  const nameWithCount = useMemo(() => `${conversation.members.length - 1} ${name}`, [conversation, name]);
  const userId = useMemo(
    () => (conversation?.members.length === 2 ? conversation.members.find(({ user }) => user.id !== me?.id)?.user.id : undefined),
    [conversation, me],
  );

  return (
    <span className="flex items-center">
      <UserAvatarComponent
        key={conversation.id}
        userId={userId}
        userName={conversation.members.length === 2 ? name : nameWithCount}
        size="xs"
        className="mr-2 h-4 w-4"
      />
      <span className="font-medium">{name}</span>
    </span>
  );
};
