import { SimpleBadge } from "@/components/shared/badges/SimpleBadge";
import { Fragment, useEffect, useState, type MouseEvent } from "react";
import { Portal } from "react-portal";
import { NavLink } from "react-router-dom";

export interface ITab {
  title: string;
  link: string;
  icon: JSX.Element;
  align: "left" | "right";
  disabled?: boolean;
  disabledText?: string;
  withBadge?: boolean;
}

interface ITabMenuProps {
  tabs: ITab[];
}

export const TabMenuComponent = ({ tabs }: ITabMenuProps): JSX.Element => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // disable navlink for disabled tabs
  const handleClickOnDisabledTab = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, isDisabled: boolean): void => {
    if (isDisabled) {
      e.preventDefault();
    }
  };

  return (
    <nav className="flex w-full text-zinc-500">
      <div id="tab-menu-left" className="flex" />
      <div id="tab-menu-right" className="ml-auto flex" />
      {tabs.map((tab) => (
        <Fragment key={`tab-menu-${tab.title}`}>
          {isMounted && (
            <Portal node={document.getElementById(`tab-menu-${tab.align}`)}>
              <NavLink
                to={tab.link}
                onClick={(e) => {
                  handleClickOnDisabledTab(e, tab.disabled ?? false);
                }}
                className={({ isActive }) => {
                  const baseClasses = `flex items-center justify-center text-zinc-800 dark:text-dark-100 py-[0.3rem] px-3 mr-4 last:mr-0 text-sm rounded-md no-underline min-w-max font-semibold 
             transition-all duration-300 tab-menu-item relative border
            ${
              tab.disabled ?? false
                ? "hidden cursor-not-allowed opacity-50"
                : "hover:bg-zinc-100 hover:border hover:dark:border-dark-500 hover:border-neutral-200 dark:hover:bg-dark-700 dark:hover:text-white group"
            }`;
                  const unActiveClasses = `border-transparent`;
                  const activeClasses =
                    "bg-zinc-100 font-semibold dark:bg-dark-700 dark:text-dark-100 dark:border-dark-600 selected border border-neutral-200";
                  return isActive ? ` ${baseClasses} ${activeClasses}` : `${baseClasses} ${unActiveClasses}`;
                }}
              >
                <i className="mr-1">{tab.icon}</i>
                <span className="hidden sm:block">{tab.title}</span>
                {tab.disabledText != null && (
                  <span className="absolute -bottom-2.5 left-9 hidden whitespace-nowrap text-2xs opacity-50 md:block">{tab.disabledText}</span>
                )}
                {tab.withBadge === true && <SimpleBadge topClass="top-[2px]" rightClass="left-[25px]" sizeClass="h-2.5" />}
              </NavLink>
            </Portal>
          )}
        </Fragment>
      ))}
    </nav>
  );
};
