import "@/assets/styles/datePicker.scss";
import "react-datepicker/dist/react-datepicker.css";

import { type IRoom } from "@/api/client";
import { ImagePreviewComponent } from "@/components/roomPage/tabs/conversation/message/ImagePreviewComponent";
import { FileTableHeaderComponent } from "@/components/roomPage/tabs/files/FileTableHeaderComponent";
import { FileTableItemComponent } from "@/components/roomPage/tabs/files/FileTableItemComponent";
import { ChannelPickerComponent } from "@/components/shared/channel/ChannelPickerComponent/ChannelPickerComponent";
import { PdfViewerComponent } from "@/components/shared/documentViewer/pdfViewer/PdfViewerComponent";
import { FileExtensionPickerComponent } from "@/components/shared/files/FileExtensionPickerComponent/FileExtensionPickerComponent";
import { useFilesContent } from "@/components/shared/filesContent/useFilesContent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { RoomPickerComponent } from "@/components/shared/room/RoomPickerComponent/RoomPickerComponent";
import { getTypeOfFileFromExtension } from "@/utils/utilities";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { TableVirtuoso } from "react-virtuoso";

interface IFilesContentComponentProps {
  room?: IRoom;
}
export const FilesContentComponent = ({ room }: IFilesContentComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    filteredRoom,
    filteredChannel,
    files,
    updateSearch,
    tryToFetchNextPage,
    filteredFileExtension,
    selectedItems,
    selectAll,
    onFilterRoomChange,
    onFilterChannelChange,
    onFilterFileExtensionChange,
    setSelectedItems,
    handleSelectAll,
    imageToPreview,
    onPreviewImage,
    onPreviewPdf,
    pdfToPreview,
    onClosePdfViewer,
    isPdfViewerVisible,
    fileExtensions,
  } = useFilesContent(room?.id);

  return (
    <>
      {imageToPreview != null ? <ImagePreviewComponent file={imageToPreview} /> : null}
      {pdfToPreview != null ? <PdfViewerComponent isVisible={isPdfViewerVisible} file={pdfToPreview} onClose={onClosePdfViewer} /> : null}
      <nav className="mx-7 flex h-14 items-center">
        {room == null && (
          <div className="mr-2 flex items-center">
            <RoomPickerComponent room={filteredRoom} isSelector onRoomChange={onFilterRoomChange} size="sm" />
          </div>
        )}
        <div className="mr-2 flex items-center">
          <ChannelPickerComponent
            room={room ?? filteredRoom}
            channel={filteredChannel}
            isSelector
            onChannelChange={onFilterChannelChange}
            size="sm"
          />
        </div>
        <div className="mr-2 flex items-center">
          <FileExtensionPickerComponent
            fileExtension={filteredFileExtension}
            isSelector
            onFileExtensionChange={onFilterFileExtensionChange}
            size="sm"
            fileExtensions={fileExtensions}
          />
        </div>
        {/* <div className={"mr-2 flex items-center relative hidden"}>
          <DatePicker
            placeholderText={t("homePage.tabs.files.filters.date.placeholder")}
            className={"bg-zinc-100 text-left px-2 h-full rounded-md text-zinc-500 text-sm w-32"}
            // @ts-expect-error react-datepicker types are bad
            selected={selectedFilters.date}
            onChange={(date: Date) => changeSelectedFilter("date", date)}
          />
          {selectedFilters.date != null && (
            <div className={"absolute right-2 cursor-pointer"} onClick={() => changeSelectedFilter("date", undefined)}>
              <ResetIcon className={"text-zinc-500 w-4 h-4"} />
            </div>
          )}
        </div> */}
        <div className="ml-auto">
          <TextInputComponent
            inputSize="sm"
            placeholder={t("homePage.tabs.files.filters.search.placeholder")}
            iconPosition="left"
            icon={<AiOutlineSearch />}
            onChange={(e) => {
              updateSearch(e.target.value);
            }}
          />
        </div>
      </nav>
      <section className="h-full w-full">
        <TableVirtuoso
          data={files}
          className="virtuoso-table"
          endReached={tryToFetchNextPage}
          fixedHeaderContent={() => (
            <FileTableHeaderComponent isInRoom={room != null} selectAll={selectAll} handleSelectAll={handleSelectAll} selectedItems={selectedItems} />
          )}
          components={{
            TableRow: (props) => {
              // eslint-disable-next-line react/prop-types
              const index = props["data-index"];
              const file = files[index];
              const isPdf = file?.extension === "pdf";

              if (isPdf) {
                return (
                  <tr
                    {...props}
                    className="cursor-pointer"
                    onClick={(event) => {
                      onPreviewPdf(event, file);
                    }}
                  />
                );
              }

              const isImage = getTypeOfFileFromExtension(file?.extension) === "image";
              if (isImage) {
                return (
                  <tr
                    {...props}
                    className="cursor-pointer"
                    onClick={(event) => {
                      onPreviewImage(event, file);
                    }}
                  />
                );
              }

              return <tr {...props} />;
            },
          }}
          itemContent={(index, file) => (
            <FileTableItemComponent
              file={file}
              isInRoom={room != null}
              key={file.id}
              isSelected={selectedItems.includes(file.id)}
              setSelectedItems={setSelectedItems}
            />
          )}
        />
      </section>
    </>
  );
};
