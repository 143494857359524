import { RoomMemberStatus, type IRoomMember } from "@/api/client";
import { RoomModal } from "@/components/homePage/modals/room/RoomModal";
import { NumberBadge } from "@/components/shared/badges/NumberBadge";
import { ContextualMenuComponent } from "@/components/shared/layout/ContextualMenuComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { RoomAvatarComponent } from "@/components/shared/room/RoomAvatarComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { ModalNames } from "@/constants/modalNames";
import { roomType } from "@/constants/room";
import { useRoomNotificationSettings } from "@/hooks/shared/notificationSettings/useRoomNotificationSettings";
import { useContextualMenu } from "@/hooks/shared/useContextualMenu";
import { useMobile } from "@/hooks/shared/useMobile";
import { PreferencesContext, usePreferences } from "@/hooks/shared/usePreferences";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AiFillStar as FillStarIcon, AiOutlineStar as OutlineStarIcon } from "react-icons/ai";
import { BiCog as SettingsIcon } from "react-icons/bi";
import { BsBellSlash, BsFillArchiveFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
interface IRoomCardProps {
  room: IRoomMember;
  isDragging?: boolean;
  size?: "sm" | "lg";
}

export const RoomCard = ({ room, isDragging = false, size = "lg" }: IRoomCardProps): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: room.id });
  const navigate = useNavigate();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { preferences } = useContext(PreferencesContext);
  const { setPreference } = usePreferences();
  const { isNotificationEnabled } = useRoomNotificationSettings(room.roomId);

  const { close: closeContextualMenu, isOpen: isContextualMenuOpen, contextualMenuPosition } = useContextualMenu();
  const { t } = useTranslation();
  const customRoomName: string = preferences?.[`room.${room.room.id}`]?.name ?? room.name;
  const { isIos } = useMobile();
  // prevent click when dropping
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, roomId: string) => {
      if (isDragging) {
        e.preventDefault();
        return;
      }
      navigate(`/dashboard/rooms/${roomId}`);
    },
    [isDragging],
  );

  const contextualMenu = useMemo(
    () => [
      {
        label: "Edit",
        onClick: () => {
          openEditRoomModal(room.room.id);
        },
      },
    ],
    [room.room.id],
  );
  const isRoomArchived = useMemo(() => room.status === RoomMemberStatus.Archived, [room.status]);

  const openEditRoomModal = useCallback((roomId: string): void => {
    openModal(ModalNames.CREATE_ROOM, <RoomModal isOrganisationRoom={room.room.type === roomType.ORGANISATION} roomId={roomId} />);
  }, []);

  const isStarred: boolean = useMemo(() => {
    return preferences?.starredRooms?.includes(room.room.id) ?? false;
  }, [preferences?.starredRooms]);

  const handleStarClick = useCallback(() => {
    const starredRooms: string[] = preferences?.starredRooms ?? [];

    if (!isStarred) {
      setPreference("starredRooms", [...starredRooms, room.room.id]);
      return;
    }

    setPreference(
      "starredRooms",
      starredRooms.filter((roomId) => roomId !== room.room.id),
    );
  }, [preferences?.starredRooms, isStarred]);

  return (
    <article
      className={`group/roomcard relative ${size === "lg" ? "mx-4 mb-10 h-32 w-32" : ""}`}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {room.badge != null && <NumberBadge count={room.badge} size={size} />}
      {size === "lg" && !isRoomArchived && (
        <div
          className={`absolute right-1 top-1 z-20 h-7 rounded-bl-xl rounded-tr-xl bg-white/70 p-1 opacity-0 transition-opacity dark:bg-dark-800/70 ${
            isIos ? "" : "group-hover/roomcard:opacity-100"
          }`}
        >
          <TooltipComponent content={isStarred ? t("poc.quickAccess.tooltipRemove") : t("poc.quickAccess.tooltipAdd")}>
            <button onClick={handleStarClick} className="ml-auto mr-1">
              {isStarred ? (
                <FillStarIcon className="h-5 w-5 text-yellow-400 opacity-80 transition-opacity hover:opacity-100 " />
              ) : (
                <OutlineStarIcon className="h-5 w-5 text-zinc-600 opacity-80 transition-opacity hover:opacity-100 dark:text-dark-200" />
              )}
            </button>
          </TooltipComponent>

          <button
            onMouseUp={() => {
              openEditRoomModal(room.room.id);
            }}
          >
            <SettingsIcon className="h-5 w-5 text-zinc-600 opacity-80 transition-opacity hover:opacity-100 dark:text-dark-200" />
          </button>
        </div>
      )}
      <button
        key={room.room.id}
        className="room w-full text-center text-zinc-700 no-underline dark:text-dark-500"
        onMouseUp={(e) => {
          handleClick(e, room.room.id);
        }}
      >
        <div
          className={`${
            size === "lg" ? "rounded-2xl border-4" : "rounded-md border-2"
          } room-image mb-1 aspect-square w-full overflow-hidden border-stone-300 transition-all duration-200 ease-in-out hover:shadow-md-centered dark:border-dark-700`}
        >
          <RoomAvatarComponent
            roomId={room.roomId}
            size={size}
            className={`h-full w-full transition-all duration-200 hover:bg-dark-25 dark:bg-dark-800 dark:hover:bg-dark-700 ${isIos ? "-mt-1" : ""}`}
          />
        </div>
        {size === "lg" && (
          <div className="room-name m-auto flex w-10/12 items-center justify-center text-sm font-semibold">
            <div className="truncate text-zinc-800 dark:text-dark-400">{customRoomName}</div>

            {isRoomArchived ? (
              <div className="ml-1 flex h-3 w-3 items-center">
                <BsFillArchiveFill />
              </div>
            ) : (
              !isNotificationEnabled && (
                <div className="ml-1 flex h-3 w-3 items-center">
                  <BsBellSlash />
                </div>
              )
            )}
          </div>
        )}
      </button>
      {
        // Contextual menu
        (isContextualMenuOpen ?? false) && (
          <ContextualMenuComponent
            links={contextualMenu}
            onClose={() => {
              closeContextualMenu();
            }}
            position={contextualMenuPosition}
          />
        )
      }
    </article>
  );
};
