import { TagColorEnum, type ITag } from "@/api/client";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { checkIfModalIsOpen, openModal } from "@/components/shared/modal/ModalService";
import { TagInputTagComponent } from "@/components/shared/tagInput/TagInputTagComponent";
import { TagEditModal } from "@/components/shared/tagInput/tagEditModal/TagEditModal";
import { useTagInput } from "@/components/shared/tagInput/useTagInput";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { ModalNames } from "@/constants/modalNames";
import { TaskTagsContext } from "@/contexts/TaskTagsContextProvider";
import { useContext, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";

interface ITagInputComponentProps {
  roomId: string;
  disabled?: boolean;
  placeholder?: string;
  taskId?: string;
  isFilter?: boolean;
  onFilterChange?: (tags: ITag) => void;
  defaultTags?: ITag[];
}

export const TagInputComponent: FunctionComponent<ITagInputComponentProps> = ({
  roomId,
  disabled,
  placeholder,
  taskId,
  isFilter = false,
  onFilterChange,
  defaultTags,
}) => {
  const { t } = useTranslation();
  const { tags: selectedTagsFromContext } = useContext(TaskTagsContext);
  const { searchTerm, setSearchTerm, isLoading, isVisible, setIsVisible, filteredAndSortedTags, toggleTagSelection, selectedTagIds } = useTagInput(
    roomId ?? "",
    defaultTags ?? selectedTagsFromContext,
    taskId,
    onFilterChange,
  );

  return (
    <TooltipComponent
      visible={isVisible}
      interactive
      onClickOutside={() => {
        if (checkIfModalIsOpen(ModalNames.TAG_EDIT)) return;
        setIsVisible(false);
      }}
      disabled={roomId === ""}
      content={
        <div className="mt-1 bg-white">
          <TextInputComponent
            placeholder={placeholder ?? t("editTaskModal.fields.labels.placeholder")}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            disabled={disabled}
            value={searchTerm}
          />
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div>
              {filteredAndSortedTags.map((tag) => (
                <TagInputTagComponent
                  taskId={taskId ?? ""}
                  isSelected={selectedTagIds.has(tag.id)}
                  inList={true}
                  key={tag.id}
                  tag={tag}
                  roomId={roomId}
                  onClick={() => {
                    toggleTagSelection(tag);
                  }}
                />
              ))}
              {filteredAndSortedTags.length === 0 ? (
                isFilter ? (
                  t("editTaskModal.fields.labels.noResults")
                ) : (
                  <button
                    className="mb-1 flex items-center"
                    onClick={() => {
                      openModal(
                        ModalNames.TAG_EDIT,
                        <TagEditModal
                          tag={{ id: searchTerm, name: searchTerm, color: TagColorEnum.Blue }}
                          roomId={roomId}
                          taskId={taskId}
                          isCreation
                        />,
                      );
                    }}
                  >
                    <FaPlus className="mr-1 h-2 w-2" />
                    {searchTerm !== "" ? (
                      <>
                        {t("editTaskModal.fields.labels.createNewTag")} {searchTerm}
                      </>
                    ) : (
                      t("editTaskModal.fields.labels.createNewTagNoSearch")
                    )}
                  </button>
                )
              ) : null}
            </div>
          )}
        </div>
      }
      placement="auto"
      key="tagInputTooltip"
    >
      <button
        type="button"
        className={`mr flex w-max cursor-pointer items-center space-x-1 bg-zinc-100 px-2 py-1 text-zinc-700 hover:opacity-80 ${
          isFilter ? "rounded-md text-sm" : "mr-1 rounded border border-dashed border-zinc-200 text-xs"
        }`}
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        {isFilter ? (
          <span className="mr-1">{t("tasks.tags")}</span>
        ) : (
          <>
            {" "}
            <FaPlus className="h-2 w-2" />
            <span>{t("editTaskModal.fields.labels.button")}</span>
          </>
        )}
      </button>
    </TooltipComponent>
  );
};
