import { type IRoomIdName } from "@/api/client";
import { ChannelItemComponent } from "@/components/shared/channel/ChannelItemComponent";
import { useChannelPickerPopinContent } from "@/components/shared/channel/ChannelPickerPopinComponent/useChannelPickerPopinContent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { type IChannelIdNameRoom } from "@/interfaces/channel";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IChannelPickerPopinContentComponentProps {
  onChannelSelected: (channel: IChannelIdNameRoom | null) => void;
  hasRemoveChannelOption?: boolean;
  isVisible: boolean;
  room: IRoomIdName | null;
}

export const ChannelPickerPopinContentComponent: FunctionComponent<IChannelPickerPopinContentComponentProps> = ({
  onChannelSelected,
  hasRemoveChannelOption = false,
  isVisible,
  room,
}) => {
  const { t } = useTranslation();
  const { channels, search, onSearchChange, ref } = useChannelPickerPopinContent(room, isVisible);

  return (
    <div className="w-52 cursor-pointer rounded-md dark:border dark:border-dark-700 dark:bg-dark-900" data-no-dnd>
      <div className="w-full p-2">
        <TextInputComponent
          ref={ref}
          value={search}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          placeholder={t("channel.picker.search.placeholder")}
        />
      </div>
      <div className="max-h-32 w-full overflow-scroll">
        {hasRemoveChannelOption ? (
          <div
            className="flex flex-row items-center px-2 py-1"
            onClick={() => {
              onChannelSelected(null);
            }}
          >
            <div className="ml-1 text-xs font-normal text-zinc-400">{t("tasks.channel.remove")}</div>
          </div>
        ) : null}
        {channels?.map((channel) => (
          <ChannelItemComponent
            key={channel.id}
            name={channel.name}
            room={room == null ? channel.room : null}
            onClick={() => {
              onChannelSelected(channel);
            }}
          />
        ))}
        {channels.length === 0 ? <div className="px-2 py-1 text-xs font-normal text-zinc-400">{t("channel.picker.search.noResult")}</div> : null}
      </div>
    </div>
  );
};
