import { type IExtendedFile } from "@/api/client";
import { FileIconComponent } from "@/components/shared/files/FileIconComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { useFileType } from "@/hooks/shared/files/useFileType";
import { useDownload } from "@/hooks/shared/useDownload";
import { format } from "date-fns";

import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface IFileTableItemComponentProps {
  file: IExtendedFile;
}

export const SearchedFileComponent = ({ file }: IFileTableItemComponentProps) => {
  const { getTypeByExtension } = useFileType();
  const { t } = useTranslation();
  const { download } = useDownload();
  const handleFileClick = useCallback(() => {
    download(file.url);
  }, []);

  return (
    <div className="cursor-pointer border-b border-b-zinc-100 pb-3 pt-1 dark:border-b-dark-700" onClick={handleFileClick}>
      <div className="mb-2">
        <span className="ml-5 text-[11px] text-gray-400">
          {file.room != null ? (
            <>
              {file.room?.name} · <span className="text-sky-600">#{file.channel?.name}</span>
            </>
          ) : (
            <span className="text-sky-600">{t("search.directMessages")}</span>
          )}
        </span>
      </div>
      <div className="-mt-px flex w-full flex-row items-center pb-0.5 pl-5">
        <UserAvatarComponent userId={file.uploadedBy.id} className="h-6 w-6" />
        <span className="ml-2 overflow-hidden text-ellipsis text-left text-xs font-semibold text-zinc-800 dark:text-dark-300">
          {file.uploadedBy.firstName} {file.uploadedBy.lastName}{" "}
        </span>
        <span className="pl-1 pr-2 text-2xs font-normal text-gray-400">{format(new Date(file.createdAt), t("date.shortFormatWithTime"))}</span>
      </div>
      <div className="w-full">
        <TooltipComponent
          content={
            <figure className="flex flex-col">
              <img src={file.url} className="max-h-[300px] w-60 rounded-md border object-cover" alt={file.name} />
            </figure>
          }
          placement="top"
          disabled={getTypeByExtension(file.extension) !== "image"}
          noPadding
        >
          <div className="flex w-full pl-1">
            <div className="ml-12 flex max-w-[80%] items-center rounded-md border bg-zinc-100 p-1.5 text-left text-white hover:text-sky-600">
              {getTypeByExtension(file.extension) === "image" ? (
                <img src={file.url} className="mr-2 aspect-square h-5 w-5 rounded border object-cover" alt={file.name} />
              ) : (
                <FileIconComponent extension={file.extension} className="mr-2" />
              )}
              <div className="w-full truncate text-xs font-medium text-zinc-800 dark:text-dark-300">{file.name}</div>
            </div>
          </div>
        </TooltipComponent>
      </div>
    </div>
  );
};
