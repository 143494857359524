import { type IMessage } from "@/api/client";
import { ForwardedMessageComponent } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/ForwardedMessageComponent";
import { useForwardMessage } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/useForwardMessage";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { type ConversationWithChannelOrDM } from "@/interfaces/conversation";
import { useTranslation } from "react-i18next";
import Select from "react-select";

interface IForwardMessageModalProps {
  messageToShare: IMessage;
}

export interface IOption {
  value: ConversationWithChannelOrDM;
  label: string;
}

export const ForwardMessageModalTabComponent = ({ messageToShare }: IForwardMessageModalProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    ClearIndicator,
    customStyles,
    options,
    handleChange,
    filterOption,
    isSearchOpened,
    editorRef,
    actions,
    forwardMessage,
    onMenuClose,
    onMenuOpen,
    formatOptionLabel,
  } = useForwardMessage(messageToShare);

  return (
    <>
      {messageToShare.conversation != null ? (
        <ModalTabComponent footer={<ModalFooterComponent actions={actions}>a</ModalFooterComponent>}>
          <>
            <Select<IOption>
              options={options}
              onChange={handleChange}
              styles={customStyles}
              menuPortalTarget={document.getElementById("global-wrapper")}
              components={{ DropdownIndicator: null, ClearIndicator }}
              placeholder={t("roomPage.tabs.room.conversation.forward.search.placeholder")}
              controlShouldRenderValue={!isSearchOpened}
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
            />
            <h2 className="mb-2 mt-4 flex w-full items-center text-sm font-bold text-zinc-500 dark:text-dark-300">
              {t("roomPage.tabs.room.conversation.forward.titleToShare")}
            </h2>
            <ForwardedMessageComponent forward={forwardMessage} className="w-full" />
            <div className="mt-2">
              <RichTextEditorComponent
                contextType="forwardedMessage"
                contextId={messageToShare.conversation.id}
                isMessagePreview={false}
                ref={editorRef}
              />
            </div>
          </>
        </ModalTabComponent>
      ) : null}
    </>
  );
};
