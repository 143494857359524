import { RoleType, type IInvitation } from "@/api/client";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { BulkInviteContext } from "@/contexts/BulkInviteContextProvider";
import { useBatchRoomInviteMutation } from "@/hooks/mutations/batchInvite/useBatchRoomInviteMutation";
import { useOrganisationActiveMemberQuery } from "@/hooks/queries/organisations/useOrganisationActiveMemberQuery";
import { useRoomActiveMemberQuery } from "@/hooks/queries/rooms/useRoomActiveMemberQuery";
import { useRoomInvitesQuery } from "@/hooks/queries/rooms/useRoomInvitesQuery";
import { useSearchInput } from "@/hooks/shared/useSearchInput";
import { filterMembersBySearch } from "@/utils/utilities";
import { useCallback, useContext, useMemo } from "react";

export const useAdminProjectRoomMemberTab = (roomId: string) => {
  const { search, updateSearch } = useSearchInput();
  const me = useContext(AuthenticationContext);
  const { setMails } = useContext(BulkInviteContext);
  const { data: members } = useRoomActiveMemberQuery(roomId);
  const { data: invites } = useRoomInvitesQuery(roomId);

  const mailsAlreadyInvited = useMemo(() => {
    const memberEmails = members?.map((member) => member.email.toLowerCase()) ?? [];
    const invitationEmails = invites?.invitations?.map((invitation) => invitation.email.toLowerCase()) ?? [];
    return [...memberEmails, ...invitationEmails];
  }, [members, invites]);

  const { mutate: inviteInBulk } = useBatchRoomInviteMutation(roomId);

  const handleBatchInvite = useCallback(async (emails: string[]) => {
    inviteInBulk({ emails });
    setMails([]);
  }, []);

  const invitations = useMemo(() => {
    return (invites?.invitations ?? []).filter((invitation) => invitation.email.toLowerCase().includes(search.toLowerCase()));
  }, [invites, search]);

  const filteredMembers = useMemo(() => {
    return filterMembersBySearch(members, search);
  }, [members, search]);

  const { data: organisationMembers } = useOrganisationActiveMemberQuery(me?.mainOrganisation?.organisationId);

  const filteredOrganisationMembers = useMemo(() => {
    if (organisationMembers == null || members == null) return [];
    const membersFiltered = filterMembersBySearch(organisationMembers, search);
    return membersFiltered.filter((organisationMember) => {
      return !members.some((member) => member.userId === organisationMember.userId);
    });
  }, [organisationMembers, search, members]);

  const invitedFilteredMembers = useMemo(() => {
    if (invites?.invitations == null) return [];

    return filterMembersBySearch(invites.invitations, search);
  }, [search, invitations]);

  const isAlreadyInvited = useMemo((): boolean => {
    if (invites?.invitations == null || members == null) return false;
    return (
      invites.invitations?.some((invitation: IInvitation) => invitation.email === search?.trim()) ||
      members.some((member) => member.email === search?.trim())
    );
  }, [invitations, search, members]);

  const onlyGuests = useMemo(() => {
    const membersWithoutMe = members?.filter((member) => member.userId !== me?.id);
    return membersWithoutMe?.every((member) => member.role.type === RoleType.Guest);
  }, [members]);

  return {
    search,
    updateSearch,
    handleBatchInvite,
    invitations,
    members,
    filteredMembers,
    mailsAlreadyInvited,
    filteredOrganisationMembers,
    invitedFilteredMembers,
    isAlreadyInvited,
    onlyGuests,
  };
};
