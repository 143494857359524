import { type IMessage, type IMessageForward, type ITask } from "@/api/client";
import { ForwardMessageModalComponent } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/ForwardMessageModalComponent";
import { useMessageThread } from "@/components/roomPage/tabs/conversation/message/useMessageThread";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { CreateTaskDrawerComponent } from "@/components/shared/tasks/CreateTaskDrawerComponent/CreateTaskDrawerComponent";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { ConversationContext } from "@/contexts/ConversationContext";
import { RoomContext } from "@/contexts/RoomContext";
import { usePinMutation } from "@/hooks/mutations/pin/usePinMutations";
import { useUnpinMutation } from "@/hooks/mutations/pin/useUnpinMutations";
import { useEditMessageQueries } from "@/hooks/shared/useEditMessageQueries";
import { useEditPinsQueries } from "@/hooks/shared/useEditPinsQueries";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import { useReaction } from "@/hooks/shared/useReaction";
import { api } from "@/services/HttpService";
import { useCallback, useContext, useMemo, type MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

export const useMessageActionsMenu = (message: IMessage, onCloseActionsMenu?: () => void) => {
  const { preferences } = useContext(PreferencesContext);
  const conversationContext = useContext(ConversationContext);
  const { openThread: openThreadHookAction } = useMessageThread();
  const { setIsEmojiPickerOpen, isEmojiPickerOpen, openEmojiPicker, pickerPosition } = useReaction();
  const { deleteMessageInQuery, editMessageInQuery } = useEditMessageQueries();
  const { t } = useTranslation();
  const { deletePinInQuery, addPinToQuery } = useEditPinsQueries();
  const { mutate: pin } = usePinMutation();
  const { mutate: unpin } = useUnpinMutation();
  const { openDrawer } = useContext(DrawerContext);
  const roomContext = useContext(RoomContext);

  const onOpenEmojiPicker: MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
    openEmojiPicker(event);
  }, []);
  const onCloseEmojiPicker = useCallback(() => {
    setIsEmojiPickerOpen(false);
    onCloseActionsMenu?.();
  }, [onCloseActionsMenu]);

  const shouldShowThreadButton = useMemo(() => {
    if (conversationContext?.conversation == null || conversationContext.members == null) return false;
    return (
      conversationContext.conversation.type === "channel" ||
      (conversationContext.conversation.type === "conversation-dm" && conversationContext.members.length > 2)
    );
  }, [conversationContext]);

  const is1to1Conversation = useMemo(() => {
    if (conversationContext?.members == null || conversationContext?.conversation?.type === "channel") return false;
    return conversationContext.members.length === 2;
  }, [conversationContext]);

  const deleteMessage = useCallback(() => {
    const deleteFunction = async () => {
      void api.messages.removeMessage(message.id, { defaultToastErrorMessage: t("toasts.message.delete.error") });
      if (message?.conversation == null) return;
      deleteMessageInQuery(message.conversation.id, message);
    };

    openModal(
      ModalNames.CONFIRM_MESSAGE_DELETION,
      <ConfirmModalComponent
        name={ModalNames.CONFIRM_MESSAGE_DELETION}
        title={t("roomPage.tabs.room.conversation.delete.title")}
        description={t("roomPage.tabs.room.conversation.delete.description")}
        action={deleteFunction}
        actionType="danger"
        actionLabel={t("roomPage.tabs.room.conversation.delete.button")}
      />,
    );
  }, [message]);

  const openForwardMessageModal = useCallback(() => {
    openModal(ModalNames.SHARE_MESSAGE, <ForwardMessageModalComponent messageToShare={message} />);
  }, [message]);

  const startReply = useCallback(() => {
    const forward: IMessageForward = {
      id: message.id,
      content: message.content,
      files: message.files,
      originalMessage: message,
      forwardType: "reply",
    };
    const event = new CustomEvent(eventNames.REPLY_TO_MESSAGE, { detail: forward });
    window.dispatchEvent(event);
  }, [message]);

  const pinMessage = useCallback(() => {
    if (conversationContext?.conversation == null) return;
    const newMessage = { ...message, pinned: true };
    editMessageInQuery(conversationContext.conversation.id, newMessage);
    addPinToQuery(message);
    pin(message.id);
  }, [conversationContext, message]);

  const unpinMessage = useCallback(() => {
    const newMessage = { ...message, pinned: false };
    if (message?.conversation == null) return;
    editMessageInQuery(message.conversation.id, newMessage);
    deletePinInQuery(message.id);
    unpin(message.id);
  }, []);

  const pinOrUnPinMessage = useCallback(() => {
    message?.pinned === true ? unpinMessage() : pinMessage();
  }, [message]);

  const canPinMessage = useMemo(() => {
    return conversationContext.conversation?.type !== "task";
  }, [conversationContext, message]);

  const openThread = useCallback(() => {
    openThreadHookAction(message?.id);
  }, [message]);

  const openTaskDrawer = useCallback(() => {
    const roomId = roomContext?.roomResponse?.room?.id;
    if (roomId == null) return;
    const defaultValues: Partial<ITask> = {
      title: t("createTaskModal.fields.title.defaultValue"),
      description: message.content,
      channel: message.conversation?.channel,
    };

    openDrawer({
      drawerType: DrawerType.CREATE_TASK,
      component: <CreateTaskDrawerComponent roomId={roomId} defaultValues={defaultValues} fromMessage />,
    });
  }, [message, roomContext?.roomResponse?.room?.id]);

  const isDark = useMemo(() => preferences.darkMode === true, [preferences]);
  const isSelf = useMemo(() => conversationContext.member?.user?.id === message?.sender?.user?.id, [message, conversationContext]);

  return {
    deleteMessage,
    openForwardMessageModal,
    shouldShowThreadButton,
    is1to1Conversation,
    startReply,
    pinMessage,
    unpinMessage,
    canPinMessage,
    openThread,
    pinOrUnPinMessage,
    openTaskDrawer,
    isDark,
    isSelf,
    onOpenEmojiPicker,
    onCloseEmojiPicker,
    isEmojiPickerOpen,
    pickerPosition,
  };
};
