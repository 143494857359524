import { type IAttachmentReference, type IFile } from "@/api/client";
import { useEmailAttachmentQuery } from "@/hooks/queries/emails/useEmailAttachmentQuery";
import { useMemo } from "react";

export const useAttachment = (emailId: string, attachment: IAttachmentReference) => {
  const { data: base64File } = useEmailAttachmentQuery(emailId, attachment.id);

  const extension = useMemo(() => {
    const splittedName = (attachment.name ?? "").split(".");

    return splittedName.length > 1 ? splittedName.pop() ?? "" : "";
  }, [attachment.name]);

  const file: IFile | null = useMemo(
    () =>
      base64File == null
        ? null
        : {
            id: attachment.id,
            name: attachment.name,
            extension,
            url: `data:image/png;base64,${base64File.base64UrlEncoded}`,
            createdAt: new Date().toISOString(),
            type: "email-attachment",
            fileSizeInBytes: attachment.fileSizeInBytes,
          },
    [base64File, extension],
  );
  return { file };
};
