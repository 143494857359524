import { type IMessage, type IMessageForward } from "@/api/client";
import { useConversationUtilities } from "@/components/roomPage/tabs/conversation/ConversationLists/useConversationUtilities";
import { ChannelOptionComponent } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/ChannelOptionComponent";
import { DMOptionComponent } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/DMOptionComponent";
import { type IOption } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/ForwardMessageModalTabComponent";
import { type CustomSelectOption } from "@/components/shared/form/select/CustomSelectComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { useSendMessageMutation } from "@/hooks/mutations/conversations/useSendMessageMutation";
import { useConversationsQuery } from "@/hooks/queries/conversations/useConversationsQuery";
import { useMessageNavigation } from "@/hooks/shared/useMessageNavigation";
import { type ConversationWithChannelOrDM } from "@/interfaces/conversation";
import { type IModalAction } from "@/interfaces/modal";
import { type IRichTextEditorRef } from "@/interfaces/richTextEditor";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoClose as ResetIcon } from "react-icons/io5";
import { components, type ClearIndicatorProps, type GroupBase, type StylesConfig } from "react-select";
import { type OptionProps } from "react-select/dist/declarations/src/components/Option";
import { type FilterOptionOption } from "react-select/dist/declarations/src/filters";
import colors from "tailwindcss/colors";

export const useForwardMessage = (messageToShare: IMessage) => {
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<IOption>();
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const editorRef = useRef<IRichTextEditorRef>(null);
  const { computeConversationTitleFromMemberList } = useConversationUtilities();

  const { navigateToContext } = useMessageNavigation();

  const forwardMessage: IMessageForward = useMemo(
    () => ({
      content: messageToShare.content,
      id: messageToShare.id,
      files: messageToShare.files,
      originalMessage: messageToShare,
      forwardType: "default",
    }),
    [messageToShare],
  );

  const { mutate: sendMessage, isPending: isSendingMessage } = useSendMessageMutation(
    "conversation",
    selectedOption?.value?.id ?? "",
    selectedOption?.value ?? undefined,
    [],
    false,
  );

  const { data: conversations } = useConversationsQuery();

  const options: IOption[] = useMemo(
    () =>
      (conversations ?? []).map((conversation) => ({
        value: conversation,
        label: "LABEL",
      })),
    [conversations],
  );

  const handleChange = useCallback(
    (event: unknown) => {
      const option = event as IOption;
      setSelectedOption(option);
    },
    [setSelectedOption],
  );

  const shareAction = useCallback(async () => {
    if (forwardMessage.originalMessage.conversation == null || selectedOption?.value == null) return;

    const conversation = selectedOption.value;
    sendMessage(
      {
        content: editorRef?.current?.getEditorContents() ?? { ops: [] },
        conversationId: conversation.id,
        forwardMessage,
      },
      {
        onSuccess: async (data) => {
          if (data != null) {
            await navigateToContext({ ...data, conversation });
            closeModal(ModalNames.SHARE_MESSAGE);
          }
        },
      },
    );
  }, [selectedOption?.value, forwardMessage]);

  const actions: IModalAction[] = useMemo(
    () => [
      {
        action: shareAction,
        label: t("roomPage.tabs.room.conversation.forward.buttons.share"),
        disabled: selectedOption?.value == null || isSendingMessage,
        type: "primary",
      },
    ],
    [selectedOption?.value, isSendingMessage],
  );

  const filterOption = useCallback((option: FilterOptionOption<IOption>, inputValue: string) => {
    const conversation = option.data.value;

    if (conversation.type === "channel") {
      return (conversation.channel.name ?? "").toLowerCase().includes(inputValue.toLowerCase());
    }
    if (conversation.type === "conversation-dm") {
      const name = computeConversationTitleFromMemberList(conversation.members);
      return name.toLowerCase().includes(inputValue.toLowerCase());
    }

    return false;
  }, []);

  const ClearIndicator = (
    props: ClearIndicatorProps<CustomSelectOption<ConversationWithChannelOrDM>, false, GroupBase<CustomSelectOption<ConversationWithChannelOrDM>>>,
  ): JSX.Element => {
    return (
      <components.ClearIndicator {...props}>
        <ResetIcon className="h-4 w-4 rotate-180" />
      </components.ClearIndicator>
    );
  };
  const customStyles: StylesConfig<CustomSelectOption<ConversationWithChannelOrDM>> = {
    // @ts-expect-error todo
    menuPortal: (previous) => ({ ...previous, zIndex: 9999 }),
    indicatorSeparator: () => ({ display: "none" }),
    // @ts-expect-error todo
    dropdownIndicator: (previous) => ({ ...previous, color: colors.stone[500] }),
    container: (previous) => ({
      ...previous,
    }),
    // @ts-expect-error todo
    valueContainer: (previous) => ({
      ...previous,
      padding: "0 0.5rem",
    }),
    // @ts-expect-error todo
    placeholder: (previous) => ({
      ...previous,
      color: colors.stone[500],
    }),
    // @ts-expect-error todo
    control: (previous) => ({
      ...previous,
      boxShadow: "none",
      backgroundColor: colors.zinc[50],
      fontSize: "0.875rem",
      height: "42px",
      minHeight: 0,
      padding: "0",
      borderColor: colors.zinc[200],
      borderWidth: "1px",
      cursor: "pointer",
    }),
    // @ts-expect-error todo
    option: (previous, state: OptionProps) => {
      return {
        ...previous,
        fontSize: "12px",
        textAlign: "left",
        width: "100%",
        color: colors.stone[500],
        backgroundColor: state.isSelected ? colors.zinc[100] : "white",
        cursor: "pointer",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
    },
    // @ts-expect-error todo
    menu: (previous) => ({
      ...previous,
      width: "100%",
    }),
    // @ts-expect-error todo
    clearIndicator: (previous) => ({
      ...previous,
      color: colors.stone[500],
      cursor: "pointer",
      zIndex: 100000,
      padding: "0",
    }),
  };

  const onMenuOpen = useCallback(() => {
    setIsSearchOpened(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setIsSearchOpened(false);
  }, []);

  const formatOptionLabel = useCallback((option: IOption) => {
    const conversation = option.value;
    if (conversation.type === "channel") {
      return <ChannelOptionComponent channel={conversation.channel} />;
    }

    if (conversation.type === "conversation-dm") {
      return <DMOptionComponent conversation={conversation} />;
    }
  }, []);

  return {
    editorRef,
    customStyles,
    ClearIndicator,
    handleChange,
    filterOption,
    forwardMessage,
    options,
    isSearchOpened,
    actions,
    onMenuOpen,
    onMenuClose,
    formatOptionLabel,
  };
};
