import { type INotification } from "@/api/client";
import { useNotification } from "@/components/homePage/drawers/notificationCenter/useNotification";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { useSystemMessage } from "@/components/shared/messages/useSystemMessage";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { useBadge } from "@/hooks/shared/useBadge";
import { useMobile } from "@/hooks/shared/useMobile";
import { useNotificationNavigation } from "@/hooks/shared/useNotificationNavigation";
import { isDeltaContentEmpty } from "@/utils/utilities";
import { formatDistanceToNowStrict } from "date-fns";
import { type DeltaStatic } from "quill";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineMailOpen as ReadIcon, HiOutlineMail as UnreadIcon } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
interface INotificationProps {
  notification: INotification;
}

export const NotificationComponent = ({ notification }: INotificationProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const { isIos } = useMobile();
  const { from, NotificationIcon, sender, onUpdateNotificationReadStatus, userName, emailThread, email } = useNotification(notification);
  const { navigateToContext } = useNotificationNavigation();
  const { handleJoinVideoCall } = useSystemMessage(notification.message);
  const { getComputedBadgeNumber } = useBadge();

  if (sender == null && emailThread == null) return <></>;

  return (
    <article
      className={`group/notification notification relative my-2 flex w-full cursor-pointer flex-col items-start rounded-lg border bg-dark-100/5 p-2 hover:bg-dark-100/30 dark:border-dark-700 dark:bg-dark-800/5 ${
        notification.isRead ? "bg-transparent" : "bg-white"
      }`}
      onClick={async () => {
        await navigateToContext(notification, emailThread, email);
      }}
    >
      {/*  notification content */}
      <div className="flex w-full flex-col">
        <div className="-mt-1 flex items-start">
          <div className="relative mr-2 h-full items-center self-center">
            <UserAvatarComponent userId={sender?.id} userName={userName} className="m-auto flex aspect-square w-[32px] items-center rounded-md" />
          </div>
          <div className="flex grow flex-col justify-between">
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center">
                <h3 className="truncate text-sm font-semibold text-zinc-800 dark:text-dark-200">{userName}</h3>
                <span className="ml-1 shrink-0 text-xs font-normal text-zinc-800 dark:text-dark-400">
                  <span className="text-zinc-500">· </span>
                  {t(`notifications.${notification.type}`)}
                </span>
              </div>
              <div className="mx-1 inline-flex items-center justify-center rounded-full bg-sky-600 px-2 py-1 text-xs font-bold leading-none text-white">
                {getComputedBadgeNumber(notification.bundleCount)}
              </div>
            </div>
            <div className="line-clamp-1 max-h-6 flex-1 text-sm font-light">
              {emailThread != null ? (
                email != null ? (
                  <>
                    <span className="font-medium text-zinc-800 dark:text-dark-400">{email?.subject}</span>
                    <span className="text-zinc-500"> · </span>
                    {email?.bodyText.visible}
                  </>
                ) : (
                  <>
                    <Skeleton width={100} />
                    <span className="text-zinc-500"> · </span>
                    <Skeleton width={100} />
                  </>
                )
              ) : null}
              {notification.message != null && (
                <>
                  {notification.message.type === "default" && (
                    <>
                      {notification.message.files != null && notification.message.files.length > 0 ? (
                        isDeltaContentEmpty(notification.message.content) ? (
                          <Trans i18nKey="notifications.attachementsSolo" i18n={i18n} values={{ count: notification.message.files.length }} />
                        ) : (
                          <Trans i18nKey="notifications.attachements" i18n={i18n} values={{ count: notification.message.files.length }} />
                        )
                      ) : null}

                      <RichTextEditorComponent
                        contextType="notification"
                        contextId={notification.message?.id}
                        isEditing={false}
                        isMessagePreview
                        defaultValue={notification.message.content as DeltaStatic}
                        messageId={notification.message.id}
                      />
                    </>
                  )}
                  {notification.message.type === "system" &&
                    (notification.message.systemData?.subtype === "new-videoconference" ? (
                      <div className="flex items-center">
                        {t("notifications.new-videoconference")}
                        <ButtonComponent type="button" onClick={handleJoinVideoCall} status="primary" size="xxs" className="ml-2 items-center">
                          {t("systemMessages.newVideoconference.button.label")}
                        </ButtonComponent>
                      </div>
                    ) : null)}
                </>
              )}

              {notification.task != null ? (
                <>
                  <span className="font-medium text-zinc-800">
                    {notification.task.taskStatus.name} <span className="text-zinc-500">· </span>
                  </span>
                  {notification.task.title}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-2 flex w-full items-center justify-between pt-1 text-xs text-gray-400">
        <div className="flex flex-row items-center">
          <i className="mr-2 flex w-[32px] items-center justify-center text-sky-600">{NotificationIcon}</i>
          <div className="shrink grow-0 truncate rounded-md bg-gray-200 px-2 py-0.5 text-zinc-500 dark:bg-dark-600 dark:text-dark-100">{from}</div>
        </div>
        <div className="grow-1 ml-2 shrink-0">
          {formatDistanceToNowStrict(new Date(notification.createdAt), {
            addSuffix: true,
          })}
        </div>
      </footer>
      <div
        className={`absolute right-1 top-6 z-20 h-7 bg-white/0 p-1 opacity-0 transition-opacity ${
          isIos ? "" : "group-hover/notification:opacity-100"
        }`}
      >
        <TooltipComponent content={notification.isRead ? t("notification.tooltip.markUnread") : t("notification.tooltip.markRead")}>
          <button onClick={onUpdateNotificationReadStatus} className="ml-auto mr-1">
            {!notification.isRead ? (
              <ReadIcon className="h-5 w-5 text-zinc-700 opacity-80 transition-opacity hover:opacity-100 dark:text-zinc-300" />
            ) : (
              <UnreadIcon className="h-5 w-5 text-zinc-700 opacity-80 transition-opacity hover:opacity-100 dark:text-zinc-300" />
            )}
          </button>
        </TooltipComponent>
      </div>
    </article>
  );
};
