import { type IAttachmentReference } from "@/api/client";
import { FileInMessageComponent } from "@/components/roomPage/tabs/conversation/message/FileInMessageComponent";
import { useAttachment } from "@/components/roomPage/tabs/emails/Attachment/useAttachment";
import { type FunctionComponent } from "react";

interface AttachmentProps {
  emailId: string;
  attachment: IAttachmentReference;
}

export const Attachment: FunctionComponent<AttachmentProps> = ({ attachment, emailId }) => {
  const { file } = useAttachment(emailId, attachment);

  if (file == null) {
    return null;
  }

  return <FileInMessageComponent key={attachment.id} file={file} />;
};
