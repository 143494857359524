import { RoleType, SubscriptionStatus } from "@/api/client";
import { SelectPlanModalComponent } from "@/components/billing/modals/selectPlanModal/SelectPlanModalComponent";
import { AppBadgeComponent } from "@/components/electron/appbadge/AppBadgeComponent";
import { NotificationsSockets } from "@/components/notifications/NotificationsSockets";
import { DrawerProvider } from "@/components/shared/drawer/DrawerProvider";
import { GlobalHeaderComponent } from "@/components/shared/layout/GlobalHeaderComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { ModalWrapperComponent } from "@/components/shared/modal/ModalWrapperComponent";
import { GlobalSockets } from "@/components/sockets/GlobalSockets";
import { JitsiFrameComponent } from "@/components/videoCall/JitsiFrameComponent";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { JitsiContextProvider } from "@/contexts/JitsiContextProvider";
import { SocketContextProvider } from "@/contexts/SocketContextProvider";
import { TaskTagsContextProvider } from "@/contexts/TaskTagsContextProvider";
import { TypingUsersContextProvider } from "@/contexts/TypingUsersContextProvider";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { useSetIdentity } from "@/hooks/shared/analytics/useSetIdentity";
import { PreferencesContext, usePreferences, type IPreferences } from "@/hooks/shared/usePreferences";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

export const LoggedInLayout = (): JSX.Element => {
  const { data: me } = useMeQuery();
  const { getAllPreferences } = usePreferences();
  const [preferences, setPreferences] = useState<IPreferences>({});

  useSetIdentity();
  useEffect(() => {
    if (me == null) return;
    setPreferences(getAllPreferences(me.id));
  }, [me]);

  useEffect(() => {
    const subscriptionStatus = me?.mainOrganisation?.organisationSubscription?.status;
    const amIOwner = me?.mainOrganisation?.role?.type === RoleType.Owner;
    if (subscriptionStatus === SubscriptionStatus.ToBeValidated && amIOwner) {
      openModal(ModalNames.PLANS, <SelectPlanModalComponent />);
    }
  }, [me]);
  return (
    <div className={`flex h-screen flex-col ${preferences.darkMode === true ? "dark" : ""}`} id="inner-wrapper">
      {me != null && (
        <AuthenticationContext.Provider value={me}>
          <DrawerProvider>
            <PreferencesContext.Provider value={{ preferences, setPreferences }}>
              <JitsiContextProvider>
                <SocketContextProvider>
                  <GlobalSockets>
                    <TypingUsersContextProvider>
                      <NotificationsSockets>
                        <TaskTagsContextProvider>
                          <GlobalHeaderComponent />
                          <Outlet />
                          <ModalWrapperComponent />
                        </TaskTagsContextProvider>
                      </NotificationsSockets>
                    </TypingUsersContextProvider>
                  </GlobalSockets>
                </SocketContextProvider>
                <JitsiFrameComponent />
              </JitsiContextProvider>
            </PreferencesContext.Provider>
          </DrawerProvider>
          {window.IS_ELECTRON && <AppBadgeComponent />}
        </AuthenticationContext.Provider>
      )}
    </div>
  );
};
