import { type INotification } from "@/api/client";
import { NotificationComponent } from "@/components/homePage/drawers/notificationCenter/NotificationComponent";
import { NotificationFilterButton } from "@/components/homePage/drawers/notificationCenter/NotificationFilterButton";
import { RoundedContainerComponent } from "@/components/shared/layout/RoundedContainerComponent";
import { useNotificationsQuery } from "@/hooks/queries/notifications/useNotificationsQuery";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegBell as BellIcon } from "react-icons/fa";
import { Virtuoso } from "react-virtuoso";

export const NotificationCenterDrawer = (): JSX.Element => {
  const [selectedFilter, setSelectedFilter] = useState<"READ" | "UNREAD">("UNREAD");
  const { t } = useTranslation();
  const { data: notificationsData, fetchPreviousPage } = useNotificationsQuery(selectedFilter);

  const notifications = useMemo(() => {
    if (notificationsData == null) return [];
    return notificationsData.pages.flatMap((page) => page.data).sort((a, b) => b.id.localeCompare(a.id));
  }, [notificationsData]);

  const tryToFetchPreviousPage = useCallback(async () => {
    await fetchPreviousPage();
  }, [notificationsData]);

  const newNotificationCount = useMemo(() => {
    return notifications?.[0]?.newNotificationCount ?? 0;
  }, [notifications]);

  return (
    <>
      <RoundedContainerComponent className="bg-dark-25 dark:bg-dark-800" hasPadding={false}>
        <nav className="flex h-14 items-center border-b border-dark-100 px-layout dark:border-dark-700">
          <NotificationFilterButton
            selected={selectedFilter === "UNREAD"}
            onClick={() => {
              setSelectedFilter("UNREAD");
            }}
            count={newNotificationCount}
          >
            <>{t("homePage.notificationCenter.unread")}</>
          </NotificationFilterButton>
          <NotificationFilterButton
            selected={selectedFilter === "READ"}
            onClick={() => {
              setSelectedFilter("READ");
            }}
          >
            <>{t("homePage.notificationCenter.read")}</>
          </NotificationFilterButton>
        </nav>
        <section className="-mt-3 mb-36 h-full overflow-y-auto">
          <div className="h-full w-full px-4 pb-14 pt-3">
            {notifications != null && notifications.length > 0 ? (
              <Virtuoso
                data={notifications}
                itemContent={(index: number, notification: INotification) => (
                  <NotificationComponent key={JSON.stringify(notification)} notification={notification} />
                )}
                endReached={tryToFetchPreviousPage}
              />
            ) : (
              <div className="flex h-full w-full items-center justify-center text-center text-sm text-zinc-500">
                <BellIcon className="mr-2 h-5 w-5" />
                {t("notifications.empty")}
              </div>
            )}
          </div>
        </section>
      </RoundedContainerComponent>
    </>
  );
};
