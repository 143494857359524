import { QueryKeys } from "@/constants/queryKeys";
import { type ConversationWithChannel, type ConversationWithChannelOrDM, type ConversationWithDM } from "@/interfaces/conversation";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useConversationsQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.MY_CONVERSATIONS],
    queryFn: async () => {
      const { data } = await api.my.getMyConversations();

      return data.data
        .map<ConversationWithChannelOrDM | null>((conversation) => {
          if (conversation.type === "channel" && conversation.channel != null) {
            const conversationWithChannel: ConversationWithChannel = conversation as ConversationWithChannel;
            return conversationWithChannel;
          }

          if (conversation.type === "conversation-dm" && conversation.directMessage != null) {
            const conversationWithChannel: ConversationWithDM = {
              ...conversation,
              type: "conversation-dm",
              directMessage: {
                ...conversation.directMessage,
                conversation: { ...conversation, messagesCount: 0 },
              },
            };

            return conversationWithChannel;
          }

          return null;
        })
        .filter((conversation): conversation is ConversationWithChannelOrDM => conversation != null);
    },
  });
};
