import { RoleType, type IRole, type IRoomMember } from "@/api/client";
import { MemberComponent } from "@/components/shared/members/MemberComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { QueryKeys } from "@/constants/queryKeys";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { api } from "@/services/HttpService";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IRoomMemberComponentProps {
  member: IRoomMember;
  isLast?: boolean;
  myRole?: IRole;
  hasGuest?: boolean;
}

export const RoomMemberComponent = ({ member, isLast = false, myRole, hasGuest = false }: IRoomMemberComponentProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const me = useContext(AuthenticationContext);
  const leaveMember = useCallback(
    async (transferOwnershipToMember: string | undefined) => {
      if (me == null || member.role == null) return;
      if (isLast || hasGuest) {
        closeModal();
        await api.my.archiveRoom(member.room.id, { archiveForAllMembers: true }, { defaultToastErrorMessage: t("toasts.room.archive.error") });
        navigate("/dashboard");
        toast.success(t("toasts.room.archive.success"));
      } else if (me.id === member.userId) {
        closeModal();
        navigate("/dashboard");
        const payload = transferOwnershipToMember != null ? { transferOwnershipToMember: { id: transferOwnershipToMember } } : {};
        await api.my.archiveRoom(member.room.id, payload, { defaultToastErrorMessage: t("toasts.room.transfer.error") });
        if (transferOwnershipToMember != null || myRole?.type === RoleType.Owner) {
          toast.success(t("toasts.room.transfer.success"));
        } else {
          toast.success(t("toasts.room.leave.success"));
        }
      } else {
        await api.rooms.archiveMember(member.room.id, member.id, { defaultToastErrorMessage: t("toasts.room.member.remove.error") });
        toast.success(t("toasts.room.member.remove.success"));
      }
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, member.room.id] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_ROOMS] });
    },
    [isLast],
  );

  const changeRole = useCallback(
    async (role: IRole) => {
      if (member.role == null) return;
      await api.rooms.setRole(member.room.id, member.id, { role: { id: role.id } }, { defaultToastErrorMessage: t("toasts.room.role.update.error") });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, member.room.id, QueryKeys.MEMBERS] });
    },
    [member],
  );

  const getRoomMembersRoleOptions = useCallback(async () => {
    const response = await api.rooms.getRoleOptions(member.roomId, member.id);
    return response.data;
  }, []);

  const isMemberOwner = member.role?.type === RoleType.Owner;
  const isMemberAdmin = member.role?.type === RoleType.Admin;
  const isMemberGuest = member.role?.type === RoleType.Guest;
  const amIOwner = myRole?.type === RoleType.Owner;
  const amIAdmin = myRole?.type === RoleType.Admin;
  const isMe = member.userId === me?.id;
  const canChangeRole = !isMemberGuest && ((amIOwner && !isMe) || (amIAdmin && !isMemberOwner && !isMemberAdmin));
  const canRemove = amIOwner || (amIAdmin && !isMemberOwner && !isMemberAdmin) || isMe;

  return (
    <MemberComponent
      member={member}
      canRemove={canRemove}
      canChangeRole={canChangeRole}
      scope="room"
      leaveMember={leaveMember}
      getMembersRoleOptions={getRoomMembersRoleOptions}
      setMemberRole={changeRole}
      shouldOpenConfirmationModal={false}
      isLast={isLast}
      hasGuests={hasGuest}
    />
  );
};
