import { type FunctionComponent } from "react";
import { RiCloseFill as CloseIcon } from "react-icons/ri";

interface DrawerHeaderComponentProps {
  title: string;
  onCloseDrawer: () => void;
}

export const DRAWER_HEADER_HEIGHT = 56;

export const DrawerHeaderComponent: FunctionComponent<DrawerHeaderComponentProps> = ({ title, onCloseDrawer }) => {
  return (
    <div
      className="flex w-full flex-row items-center justify-between border-b bg-dark-25 px-6 text-zinc-800 dark:border-dark-700 dark:bg-dark-800 dark:text-dark-100"
      style={{ height: DRAWER_HEADER_HEIGHT }}
    >
      <h1 className="font-semibold ">{title}</h1>
      <CloseIcon className="h-5 w-5 cursor-pointer" onClick={onCloseDrawer} />
    </div>
  );
};
