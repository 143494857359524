import "@/assets/styles/datePicker.scss";
import "react-datepicker/dist/react-datepicker.css";

import { TaskItemComponent } from "@/components/roomPage/tabs/tasks/TaskItemComponent";
import { TaskStatusColumnComponent } from "@/components/roomPage/tabs/tasks/TaskStatusColumnComponent";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { RoomPickerComponent } from "@/components/shared/room/RoomPickerComponent/RoomPickerComponent";
import { CreateTaskStatusPopinComponent } from "@/components/shared/tasks/CreateTaskStatusPopinComponent/CreateTaskStatusPopinComponent";
import { TaskPriorityComponent } from "@/components/shared/tasks/TaskPriorityComponent/TaskPriorityComponent";
import { TaskTagFilterComponent } from "@/components/shared/tasks/TaskTagFilterComponent";
import { useTasksContent } from "@/components/shared/tasksContent/useTasksContent";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import { useTranslation } from "react-i18next";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineTaskAlt as AddIcon } from "react-icons/md";

interface ITasksContentComponentProps {
  roomId?: string;
}
export const TasksContentComponent = ({ roomId }: ITasksContentComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    tasks,
    activeTask,
    activeColumn,
    onDragStart,
    onDragEnd,
    sensors,
    onCreateTask,
    filteredPriority,
    onFilterPriorityChange,
    filteredRoom,
    onFilterRoomChange,
    onDragOver,
    taskStatuses,
    onCreateTaskStatusPopinClose,
    onCreateTaskStatusPopinOpen,
    isCreateTaskStatusPopinVisible,
    createTaskStatusPopinKey,
    filteredTags,
    onFilterTagChange,
  } = useTasksContent(roomId);

  return (
    <>
      <nav className="flex h-14 items-center justify-between px-7">
        <div className="mr-2 flex items-center">
          <TaskPriorityComponent priority={filteredPriority} displayNoPriority onPriorityChange={onFilterPriorityChange} isSelector />
          {roomId == null ? (
            <RoomPickerComponent className="ml-4" room={filteredRoom} onRoomChange={onFilterRoomChange} isSelector size="sm" />
          ) : null}
        </div>
        <div className="flex items-center rounded-md border border-zinc-200 bg-zinc-100">
          <TaskTagFilterComponent roomId={roomId} filteredTags={filteredTags} onFilterTagChange={onFilterTagChange} />
        </div>
        <div className="ml-auto flex">
          <ButtonComponent onClick={onCreateTask} status="secondary" size="sm" icon={<AddIcon />}>
            {t("tasks.createButton")}
          </ButtonComponent>
        </div>
      </nav>
      <section className="z-20 flex h-full w-full flex-row overflow-x-scroll border-t border-zinc-200 px-3 dark:border-dark-700">
        <DndContext onDragOver={onDragOver} onDragStart={onDragStart} onDragEnd={onDragEnd} autoScroll={false} sensors={sensors}>
          <SortableContext items={taskStatuses} strategy={horizontalListSortingStrategy}>
            {taskStatuses?.map((status) => {
              return (
                <TaskStatusColumnComponent
                  key={status.id}
                  status={status}
                  tasks={tasks}
                  isDragging={activeColumn != null && activeColumn.id === status.id}
                />
              );
            })}

            <DragOverlay zIndex={20}>
              {activeTask != null ? <TaskItemComponent task={activeTask} isActive={false} isDragging /> : null}
              {activeColumn != null ? <TaskStatusColumnComponent status={activeColumn} tasks={tasks} isOverlay={true} /> : null}
            </DragOverlay>
          </SortableContext>
        </DndContext>
        <div className="min-w-[365px]  max-w-[365px] p-4">
          <CreateTaskStatusPopinComponent
            isVisible={isCreateTaskStatusPopinVisible}
            onCloseTooltip={onCreateTaskStatusPopinClose}
            key={createTaskStatusPopinKey}
          >
            <button
              onClick={onCreateTaskStatusPopinOpen}
              className="flex items-center text-sm font-semibold text-sky-600 transition-colors hover:text-sky-800"
            >
              <IoAddCircleOutline className="mr-1 h-4 w-4 " />
              <span>{t("tasks.createColumnButton")}</span>
            </button>
          </CreateTaskStatusPopinComponent>
        </div>
      </section>
    </>
  );
};
