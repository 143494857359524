import { type IChannelConversation, type IConversationMessagesSend } from "@/api/client";
import { eventNames } from "@/constants/eventNames";
import { QueryKeys } from "@/constants/queryKeys";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { RoomContext } from "@/contexts/RoomContext";
import { useDraft } from "@/hooks/shared/useDraft";
import { useEditMessageQueries } from "@/hooks/shared/useEditMessageQueries";
import { useFormatMessageForOptimistic } from "@/hooks/shared/useFormatMessageForOptimistic";
import { type ISendMutation } from "@/interfaces/richTextEditor";
import { addMessage as addMessageApi } from "@/services/ConversationService";
import { type InternalFile } from "@/types/fileTypes";
import { isDeltaContentEmpty } from "@/utils/utilities";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useSendMessageMutation = (
  contextType: string,
  contextId: string,
  conversation: IChannelConversation | undefined,
  files: InternalFile[],
  areFilesUploading: boolean,
) => {
  const { t } = useTranslation();
  const { replaceMessageIdInQuery } = useEditMessageQueries();
  const me = useContext(AuthenticationContext);
  const roomContext = useContext(RoomContext);
  const { saveDraft } = useDraft();
  const { formatMessageForOptimistic } = useFormatMessageForOptimistic(conversation, files);

  return useMutation({
    mutationFn: async (data: ISendMutation) => {
      if (me == null || conversation == null) {
        return null;
      }

      const isContentEmpty = isDeltaContentEmpty(data.content);

      if (isContentEmpty && files.length === 0 && data.forwardMessage == null) {
        return null;
      }
      if (files.length > 0 && areFilesUploading) {
        toast.error(t("roomPage.tabs.room.conversation.message.toasts.waitForUploadError"));
        return null;
      }

      const newMessageForOptimistic = formatMessageForOptimistic(data);

      const newMessageEvent = new CustomEvent(eventNames.NEW_MESSAGE, {
        detail: {
          message: newMessageForOptimistic,
        },
      });

      const resetMessageFileEvent = new CustomEvent(eventNames.RESET_MESSAGE_FILE, { detail: contextId });
      const resetMessageReplyEvent = new CustomEvent(eventNames.RESET_REPLY, { detail: contextId });

      window.dispatchEvent(newMessageEvent);
      window.dispatchEvent(resetMessageFileEvent);
      window.dispatchEvent(resetMessageReplyEvent);

      const messageForApi: IConversationMessagesSend = {
        content: newMessageForOptimistic.content,
        files: files?.map((file) => ({ id: file.id })),
        sentFromRoomId: roomContext?.roomResponse?.room?.id,
        parentId: newMessageForOptimistic.parent?.id,
        forwardMessageId: newMessageForOptimistic.forward?.id,
        forwardType: newMessageForOptimistic.forward?.forwardType,
      };

      const newMessageResponse = await addMessageApi(messageForApi, conversation.id);
      saveDraft(contextId, null, true);

      const queryKeys = [QueryKeys[contextType.toUpperCase() as keyof typeof QueryKeys], contextId, QueryKeys.MESSAGES];
      replaceMessageIdInQuery(queryKeys, newMessageResponse, newMessageForOptimistic.id);

      // add the member in the sender object of the response
      if (newMessageResponse.sender != null) {
        newMessageResponse.sender.user = me;
      }

      return newMessageResponse;
    },
  });
};
