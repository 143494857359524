import { type ISearchedMessage } from "@/api/client";
import { MessageHeaderComponent } from "@/components/roomPage/tabs/conversation/message/MessageHeaderComponent";
import { ReactionListComponent } from "@/components/roomPage/tabs/conversation/ReactionListComponent";
import { MessageHighlightComponent } from "@/components/search/MessageHightLightComponent";
import { useMessage } from "@/hooks/shared/useMessage";
import { format } from "date-fns";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

interface ISearchedMessageComponentProps {
  message: ISearchedMessage;
}

export const SearchedMessageComponent = ({ message }: ISearchedMessageComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const { handleMouseEnter, handleMouseLeave, messageRef, handleKeyDown, hasReaction, handleSearchedMessageClick, location } = useMessage({
    message,
  });
  return (
    <Fragment key={message.id}>
      <div
        className="group/message message-wrapper relative cursor-pointer border-b border-b-zinc-100 px-layout dark:border-b-dark-700"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleKeyDown}
        onClick={async () => {
          await handleSearchedMessageClick(message);
        }}
      >
        <article className="message flex w-full flex-col py-3">
          <div className="mb-2 flex items-center justify-between">
            <span className="text-[11px] font-normal text-gray-400">{location}</span>
          </div>
          <MessageHeaderComponent message={message} isForwarded showForward={false} isInSearch />
          <div className="flex max-w-max">
            <span
              className="mr-2 h-6 w-6 pt-2 text-2xs font-medium text-gray-400 opacity-0 transition-opacity group-hover/message:opacity-100"
              style={{ direction: "rtl" }}
            >
              <span className="h-6 w-6">{format(new Date(message.createdAt), t("date.timeFormat"))}</span>
            </span>
            <div className="relative w-full">
              <div
                ref={messageRef}
                className="message-content relative flex flex-col rounded-md border border-zinc-200/60 bg-zinc-100 transition-all duration-1000 dark:border-dark-700/60 dark:bg-dark-600"
              >
                <div className="px-2 py-1">
                  <MessageHighlightComponent highlights={message.highlights ?? []} />
                </div>
                {hasReaction && <ReactionListComponent message={message} readOnly />}
              </div>
            </div>
          </div>
        </article>
      </div>
    </Fragment>
  );
};
