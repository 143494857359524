import { SearchFileResultComponent } from "@/components/search/SearchFileResultComponent";
import { SearchMessageResultComponent } from "@/components/search/SearchMessageResultComponent";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { eventNames } from "@/constants/eventNames";
import { useFilesQuery } from "@/hooks/queries/files/useFilesQuery";
import { useSearchMessagesQuery } from "@/hooks/queries/search/useSearchMessagesQuery";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";

interface ISearchContainerComponentProps {
  searchQuery: string;
}
enum SEARCH_TYPES {
  MESSAGES = "messages",
  FILES = "files",
}

export const SearchContainerComponent = ({ searchQuery }: ISearchContainerComponentProps) => {
  const { t } = useTranslation();

  const [activeSearchType, setActiveSearchType] = useState<SEARCH_TYPES>(SEARCH_TYPES.MESSAGES);

  const handleBackdropClick = useCallback(() => {
    const event = new CustomEvent(eventNames.CLOSE_SEARCH);
    window.dispatchEvent(event);
  }, []);

  const { data: messagesData, fetchNextPage: fetchNextPageMessage } = useSearchMessagesQuery({ messageSearchContent: searchQuery });
  const searchedMessages = useMemo(() => {
    if (messagesData?.pages == null) return [];
    return messagesData?.pages?.map((page) => page.data)?.flat();
  }, [messagesData?.pages]);

  const { data: filesData, fetchNextPage: fetchNextPageFile } = useFilesQuery({
    name: searchQuery,
  });

  const searchedFiles = useMemo(() => {
    if (filesData?.pages == null) return [];
    return filesData?.pages?.map((page) => page.data)?.flat();
  }, [filesData?.pages]);

  return (
    <Portal node={document.getElementById("inner-wrapper")}>
      <div className="search-result w-5/10 absolute left-1/2 top-10 z-[92] flex -translate-x-1/2 flex-col rounded-md border bg-white shadow-md-centered dark:border-dark-700 dark:bg-dark-900 md:w-1/3">
        <nav className="border-b-zinc-150 flex flex-row items-center border-b p-layout py-2 dark:border-b-dark-700">
          <ButtonComponent
            type="button"
            className="mr-3"
            size="xs"
            status={activeSearchType === SEARCH_TYPES.MESSAGES ? "primary" : "secondary"}
            onClick={() => {
              setActiveSearchType(SEARCH_TYPES.MESSAGES);
            }}
          >
            {t("header.search.messages.button")} ({messagesData?.pages?.[0]?.pagination.totalCount ?? 0})
          </ButtonComponent>
          <ButtonComponent
            type="button"
            size="xs"
            status={activeSearchType === SEARCH_TYPES.FILES ? "primary" : "secondary"}
            onClick={() => {
              setActiveSearchType(SEARCH_TYPES.FILES);
            }}
          >
            {t("header.search.files.button")}
            {/* @ts-expect-error no total count from the back right now */}
            {filesData?.pages?.[0]?.pagination.totalCount != null ? <>({filesData?.pages?.[0]?.pagination.totalCount})</> : ""}
          </ButtonComponent>
        </nav>
        {activeSearchType === SEARCH_TYPES.MESSAGES ? (
          <SearchMessageResultComponent searchResult={searchedMessages} fetchNextPage={fetchNextPageMessage} />
        ) : (
          <SearchFileResultComponent searchResult={searchedFiles} fetchNextPage={fetchNextPageFile} />
        )}
      </div>
      <div className="absolute inset-0 z-[91] h-full w-full opacity-0" onClick={handleBackdropClick}></div>
    </Portal>
  );
};
