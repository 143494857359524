import { type IRoomIdName } from "@/api/client";
import { useChannelPicker } from "@/components/shared/channel/ChannelPickerComponent/useChannelPicker";
import { ChannelPickerPopinComponent } from "@/components/shared/channel/ChannelPickerPopinComponent/ChannelPickerPopinComponent";
import { type IChannelIdNameRoom } from "@/interfaces/channel";
import { type FunctionComponent, type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { BsFillCaretDownFill as SelectorIcon } from "react-icons/bs";

interface IChannelPickerComponentProps {
  channel: IChannelIdNameRoom | null;
  onChannelChange?: (channel: IChannelIdNameRoom | null) => void;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  size?: "lg" | "md" | "sm";
  isSelector?: boolean;
  room: IRoomIdName | null;
}

export const ChannelPickerComponent: FunctionComponent<IChannelPickerComponentProps> = ({
  channel,
  onChannelChange,
  className,
  size = "md",
  isSelector = false,
  room,
}) => {
  const { isChannelTooltipVisible, onCloseTooltip, onChannelClick, onChannelSelected } = useChannelPicker(channel, onChannelChange);
  const { t } = useTranslation();

  return (
    <ChannelPickerPopinComponent
      isVisible={isChannelTooltipVisible}
      onCloseTooltip={onCloseTooltip}
      onChannelSelected={onChannelSelected}
      hasRemoveChannelOption={isSelector && channel != null}
      room={room}
    >
      <div
        className={`flex flex-row items-center justify-between ${onChannelChange != null ? "cursor-pointer" : ""} ${
          isSelector ? "rounded-md border border-zinc-200 bg-zinc-100 px-2 py-1 hover:opacity-75" : "dark:border-dark-700 dark:bg-dark-800"
        } ${className ?? ""}`}
        onClick={onChannelClick}
      >
        <div className="flex flex-row items-center">
          <div
            className={`${size === "lg" ? "ml-2" : "ml-1"} font-normal ${size === "lg" || size === "sm" ? "text-sm" : "text-xs"} ${
              isSelector ? "text-zinc-700" : onChannelChange == null ? "text-sky-600" : "text-zinc-500"
            }`}
          >
            {channel != null ? `# ${channel.name}` : isSelector ? t("channel.picker.selectChannel") : t("channel.picker.noChannel")}
          </div>
        </div>
        {onChannelChange != null ? (
          isSelector ? (
            <SelectorIcon className="ml-1 h-3 w-3 text-zinc-700" />
          ) : (
            <div className="text-xs font-semibold text-sky-600">{t("channel.picker.pick")}</div>
          )
        ) : null}
      </div>
    </ChannelPickerPopinComponent>
  );
};
