import { type IRoomIdName } from "@/api/client";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { RoomItemComponent } from "@/components/shared/room/RoomItemComponent";
import { useRoomPickerPopinContent } from "@/components/shared/room/RoomPickerPopinComponent/useRoomPickerPopinContent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IRoomPickerPopinContentComponentProps {
  onRoomSelected: (room: IRoomIdName | null) => void;
  hasRemoveRoomOption?: boolean;
  isVisible: boolean;
}

export const RoomPickerPopinContentComponent: FunctionComponent<IRoomPickerPopinContentComponentProps> = ({
  onRoomSelected,
  hasRemoveRoomOption = false,
  isVisible,
}) => {
  const { t } = useTranslation();
  const { rooms, isLoading, search, onSearchChange, ref } = useRoomPickerPopinContent(isVisible);

  return isLoading ? (
    <LoaderComponent />
  ) : (
    <div className="w-52 cursor-pointer rounded-md dark:border dark:border-dark-700 dark:bg-dark-900" data-no-dnd>
      <div className="w-full p-2">
        <TextInputComponent
          ref={ref}
          value={search}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          placeholder={t("room.picker.search.placeholder")}
        />
      </div>
      <div className="max-h-32 w-full overflow-scroll">
        {hasRemoveRoomOption ? (
          <div
            className="flex flex-row items-center px-2 py-1"
            onClick={() => {
              onRoomSelected(null);
            }}
          >
            <div className="room-image aspect-square h-5 w-5 overflow-hidden rounded-md border border-dashed border-stone-300 transition-all duration-200 ease-in-out dark:border-dark-700"></div>
            <div className="ml-1 text-xs font-normal text-zinc-400">{t("tasks.room.remove")}</div>
          </div>
        ) : null}
        {rooms?.map((room) => (
          <RoomItemComponent
            key={room.id}
            room={room}
            onClick={() => {
              onRoomSelected(room);
            }}
          />
        ))}
        {rooms.length === 0 ? <div className="px-2 py-1 text-xs font-normal text-zinc-400">{t("room.picker.search.noResult")}</div> : null}
      </div>
    </div>
  );
};
