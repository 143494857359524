export enum QueryKeys {
  ME = "me",
  MY_ROOMS = "my-rooms",
  MY_DMS = "my-dms",
  MY_COLLABORATORS = "my-collaborators",
  MY_ORGANISATION = "my-organisation",
  ROOMS = "rooms",
  ORGANISATIONS = "organisations",
  CHANNELS = "channels",
  CONFIRMED = "confirmed",
  DMS = "dms",
  PENDING = "pending",
  MEMBERS = "members",
  MESSAGES = "messages",
  MY_CONVERSATIONS = "my-conversations",
  CONVERSATIONS = "conversations",
  // TODO: remove these two keys to uniformize the naming on next refactor
  CONVERSATION = "conversation",
  MESSAGE = "message",
  SEARCH = "search",
  NOTIFICATIONS = "notifications",
  FILES = "files",
  USERS = "users",
  DMS_BADGE = "dms-badge",
  NOTIFICATION_SETTINGS = "notification-settings",
  BILLING = "billing",
  PLANS = "plans",
  PLAN_URL = "plans-url",
  TASKS = "tasks",
  TASK = "task",
  ACTIVE_ROOMS = "active-rooms",
  GLOBAL_SEARCH = "global-search",
  PINS = "pins",
  GOOGLE_CALENDAR_EVENTS = "google-calendar-events",
  TASKS_STATUSES = "tasks-statuses",
  EMAILS = "emails",
  EMAIL = "email",
  EMAIL_ATTACHMENT = "email-attachment",
  EMAIL_THREAD = "emails-thread",
  EMAIL_THREADS = "emails-threads",
  EMAILS_BADGE = "emails-badge",
  TAGS = "tags",
  ENHANCE_BY_AI = "enhance-by-ai",
}
