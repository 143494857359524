import { FileType, type IChannelConversation, type IConversationMember, type IMessage, type MessageConversation } from "@/api/client";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { type ISendMutation } from "@/interfaces/richTextEditor";
import { type InternalFile } from "@/types/fileTypes";
import { getTypeOfFileFromExtension } from "@/utils/utilities";
import { useCallback, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

export const useFormatMessageForOptimistic = (conversation: IChannelConversation | undefined, files: InternalFile[]) => {
  const uuidForOptimistic = uuidv4();
  const me = useContext(AuthenticationContext);

  const formatMessageForOptimistic = useCallback(
    (data: ISendMutation) => {
      const sender: Partial<IConversationMember> = { user: me };
      const createdAtForOptimistic = new Date().toISOString();
      let newMessageForOptimistic: IMessage = {
        id: `OPTIMISTIC_ID_${uuidForOptimistic}`,
        content: data.content,
        sender: sender as IConversationMember,
        createdAt: createdAtForOptimistic,
        // @ts-expect-error BACK NEED TO FIX THIS deleteAt: string | null
        deletedAt: null,
        conversation: conversation as MessageConversation,
        files: files.map((file) => {
          const type = getTypeOfFileFromExtension(file.extension) === "audio" ? FileType.VoiceNote : FileType.Default;
          return {
            id: file.id,
            name: file.name,
            url: ["audio", "image", "pdf"].includes(getTypeOfFileFromExtension(file.extension)) && file.base64 != null ? file.base64 : "",
            extension: file.extension,
            image:
              file.width != null && file.height != null
                ? {
                    width: file.width,
                    height: file.height,
                  }
                : undefined,
            createdAt: new Date().toISOString(),
            type,
          };
        }),
      };
      if (data.parentId != null) {
        const parent: Partial<IMessage> = {
          id: data.parentId,
        };
        // @ts-expect-error to type later
        newMessageForOptimistic = { ...newMessageForOptimistic, parent };
      }

      if (data.forwardMessage != null) {
        newMessageForOptimistic = {
          ...newMessageForOptimistic,
          forward: data.forwardMessage,
        };
      }

      return newMessageForOptimistic;
    },
    [files],
  );

  return { formatMessageForOptimistic };
};
