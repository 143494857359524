import { type ITask } from "@/api/client";
import { TaskItemContextMenuComponent } from "@/components/roomPage/tabs/tasks/TaskItemContextMenuComponent";
import { useTaskItem } from "@/components/roomPage/tabs/tasks/useTaskItem";
import { ChannelPickerComponent } from "@/components/shared/channel/ChannelPickerComponent/ChannelPickerComponent";
import { RoomPickerComponent } from "@/components/shared/room/RoomPickerComponent/RoomPickerComponent";
import { TagInputTagComponent } from "@/components/shared/tagInput/TagInputTagComponent";
import { TaskAssigneesComponent } from "@/components/shared/tasks/TaskAssigneesComponent/TaskAssigneesComponent";
import { TaskDeadlineComponent } from "@/components/shared/tasks/TaskDeadlineComponent/TaskDeadlineComponent";
import { TaskPriorityComponent } from "@/components/shared/tasks/TaskPriorityComponent/TaskPriorityComponent";
import { TaskStatusItemComponent } from "@/components/shared/tasks/TaskStatusItemComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type IChannelIdNameRoom } from "@/interfaces/channel";
import { BsThreeDots } from "react-icons/bs";
import { FaLock as LockCloseIcon } from "react-icons/fa";
import { MdChatBubbleOutline as CommentIcon } from "react-icons/md";

interface ITaskItemComponentProps {
  task: ITask;
  isActive: boolean;
  isDragging?: boolean;
  isInHome?: boolean;
}

const ITEM_HEIGHT = 120;
const ITEM_MARGIN = 8;

export const TaskItemComponent = ({ task, isActive, isDragging = false, isInHome = false }: ITaskItemComponentProps) => {
  const { onTaskClick, onPriorityChange, onDeadlineChange, onContextMenuButtonClick, isContextMenuOpen, onContextMenuClickOutside } =
    useTaskItem(task);

  if (isActive) {
    return <div style={{ height: ITEM_HEIGHT + ITEM_MARGIN }} />;
  }
  return (
    <div style={{ height: ITEM_HEIGHT + ITEM_MARGIN, paddingBottom: ITEM_MARGIN }}>
      <div
        onClick={onTaskClick}
        className={`h-full w-full ${
          isDragging ? "cursor-grab" : "cursor-pointer"
        } group/task flex flex-col items-start justify-between rounded-md border border-gray-200 bg-gray-50 px-3 py-2 shadow-sm hover:bg-gray-100 dark:border-dark-600 dark:bg-dark-800`}
      >
        <div className="flex h-6 w-full flex-row items-center justify-between">
          <div className="flex items-center">
            <RoomPickerComponent room={task.channel?.room ?? null} />
            <div className="ml-1 text-xs font-normal text-zinc-500">·</div>
            <ChannelPickerComponent room={task.channel?.room ?? null} channel={(task.channel as IChannelIdNameRoom) ?? null} />
          </div>
          <div className="ml-auto flex items-center">
            <TaskDeadlineComponent onDeadlineChange={onDeadlineChange} deadline={task.deadline ?? null} />
            {task.isPrivate ? <LockCloseIcon className="ml-2 h-2 w-2 text-zinc-500" /> : null}
          </div>
          <TooltipComponent
            content={<TaskItemContextMenuComponent task={task} />}
            visible={isContextMenuOpen}
            placement="bottom"
            arrow={false}
            noPadding
            interactive
            onClickOutside={onContextMenuClickOutside}
          >
            <button
              className="group w-0 overflow-hidden opacity-50 transition-all hover:opacity-100 group-hover/task:ml-1 group-hover/task:w-4"
              onClick={onContextMenuButtonClick}
            >
              <BsThreeDots className="h-4 w-4" />
            </button>
          </TooltipComponent>
        </div>
        <div className="h-10 break-all text-left text-[13px] font-normal dark:text-dark-200"> {task.title}</div>
        <div className="flex h-6 w-full flex-row items-center justify-between">
          <TaskAssigneesComponent
            taskId={task.id}
            assignedUsers={task.assignedUsers ?? []}
            roomId={task.channel?.room?.id ?? null}
            channelId={task.channel?.id ?? null}
          />
          <div className="flex w-1/2 justify-end space-x-1">
            {(task.todos?.totalCount ?? 0) > 0 ? (
              <div className="rounded-md p-1 text-xs text-sky-500">
                ({task.todos?.checkedCount ?? 0}/{task.todos?.totalCount ?? 0})
              </div>
            ) : null}
            {task.conversation.messagesCount > 0 ? (
              <div className="flex items-center rounded-md bg-zinc-200 px-3 py-1 text-xs text-zinc-500">
                <CommentIcon className="mr-1" />
                {task.conversation.messagesCount}
              </div>
            ) : null}
            <TaskPriorityComponent onPriorityChange={onPriorityChange} priority={task.priority ?? null} />
            {isInHome ? <TaskStatusItemComponent status={task.taskStatus} showTooltip={isInHome} /> : null}
          </div>
        </div>
        {task?.tags != null && task.tags?.length > 0 ? (
          <div className="-mb-2 mt-2 w-full overflow-y-visible ">
            <div className="flex w-full overflow-scroll  pb-2.5">
              {task.tags?.map((tag) => (
                <TagInputTagComponent size="sm" roomId={task.channel?.room?.id ?? ""} taskId={task.id} key={tag.id} tag={tag} onClick={() => {}} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
