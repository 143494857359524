import { type IRoomIdName, type ITag, type ITask, type ITaskStatus, type TaskPriorityEnum } from "@/api/client";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { CreateTaskDrawerComponent } from "@/components/shared/tasks/CreateTaskDrawerComponent/CreateTaskDrawerComponent";
import { eventNames } from "@/constants/eventNames";
import { DraggableType } from "@/constants/task";
import { useReorderTaskStatusMutation } from "@/hooks/mutations/tasks/useReorderTaskStatusMutation";
import { useUpdateTaskMutation } from "@/hooks/mutations/tasks/useUpdateTaskMutation";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { useTasksQuery } from "@/hooks/queries/tasks/useTasksQuery";
import { useTaskStatusesQuery } from "@/hooks/queries/tasks/useTaskStatusesQuery";
import { MouseSensor } from "@/utils/dndSensor";
import { useSensor, useSensors, type DragEndEvent, type DragOverEvent, type DragStartEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const useTasksContent = (roomId: string | undefined) => {
  const { data: meData } = useMeQuery();
  const [filteredPriority, setFilteredPriority] = useState<TaskPriorityEnum | null>(null);
  const [filteredRoom, setFilteredRoom] = useState<IRoomIdName | null>(null);
  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);
  const { data: tasksData } = useTasksQuery({
    where: {
      roomId: roomId ?? filteredRoom?.id ?? undefined,
      assignedToUserId: roomId == null ? meData?.id : undefined,
      priority: filteredPriority ?? undefined,
      tagIds: filteredTags.length > 0 ? filteredTags.map((tag) => tag.id) : undefined,
    },
  });

  const { data: taskStatusesData } = useTaskStatusesQuery(roomId);
  const { mutate: updateTask } = useUpdateTaskMutation();
  const { mutate: reorderTaskStatus } = useReorderTaskStatusMutation();

  const { openDrawer } = useContext(DrawerContext);

  const [tasks, setTasks] = useState<ITask[]>([]);
  const [taskStatuses, setTaskStatuses] = useState<ITaskStatus[]>([]);
  const [activeTask, setActiveTask] = useState<ITask | null>(null);
  const [activeColumn, setActiveColumn] = useState<ITaskStatus | null>(null);
  const [isCreateTaskStatusPopinVisible, setIsCreateTaskStatusPopinVisible] = useState(false);

  const onFilterTagChange = useCallback((tag: ITag) => {
    setFilteredTags((prev) => {
      if (prev.some((currentTag) => currentTag.id === tag.id)) {
        return prev.filter((currentTag) => currentTag.id !== tag.id);
      }
      return [...prev, tag];
    });
  }, []);
  const createTaskStatusPopinKey = useMemo(() => `createTaskStatusPopinKey-${uuidv4()}`, [isCreateTaskStatusPopinVisible]);

  useEffect(() => {
    setTasks(tasksData ?? []);
    setTaskStatuses(taskStatusesData ?? []);
  }, [tasksData, taskStatusesData]);

  const onCloseTaskStatusCreationPopin = useCallback(() => {
    setIsCreateTaskStatusPopinVisible(false);
  }, []);

  useEffect(() => {
    if (!isCreateTaskStatusPopinVisible) return;
    window.addEventListener(eventNames.CLOSE_TASK_STATUS_CREATION_POPIN, onCloseTaskStatusCreationPopin);

    return () => {
      if (!isCreateTaskStatusPopinVisible) return;
      window.removeEventListener(eventNames.CLOSE_TASK_STATUS_CREATION_POPIN, onCloseTaskStatusCreationPopin);
    };
  }, [isCreateTaskStatusPopinVisible]);

  const onTaskHover = useCallback(
    (taskId: string, status: string) => {
      setTasks((tasks) => tasks.map((task) => (task.id === taskId ? { ...task, taskStatus: { ...task.taskStatus, id: status } } : task)));
    },
    [setTasks],
  );

  const onDragOver = useCallback(
    (event: DragOverEvent) => {
      if (event.over?.id == null) return;
      if (event.active.data.current?.type === DraggableType.TASK) {
        onTaskHover(event.active.id.toString(), event.over.id.toString());
      }
    },
    [onTaskHover],
  );

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === DraggableType.TASK_STATUS) {
      setActiveColumn(taskStatuses.find((taskStatus) => taskStatus.id === (event.active.id as string)) ?? null);
    } else if (event.active.data.current?.type === DraggableType.TASK) {
      const taskId = event.active.id as string;
      const task = tasks.find((task) => task.id === taskId);
      setActiveTask(task ?? null);
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    setActiveTask(null);
    setActiveColumn(null);

    if (active == null || over == null) return;

    const {
      data: { current: currentActiveData },
    } = active;
    const {
      data: { current: currentOverData },
    } = over;

    if (currentActiveData?.type === DraggableType.TASK_STATUS && roomId != null && currentOverData?.sortable.index != null) {
      setTaskStatuses(arrayMove(taskStatuses, currentActiveData.sortable.index, currentOverData.sortable.index));
      reorderTaskStatus({
        roomId,
        taskStatusId: active.id as string,
        to: currentOverData.sortable.index,
      });
      return;
    }

    const task = tasksData?.find((task) => task.id === active.id);
    const newStatus = over.id.toString();

    if (task != null && task.taskStatus.id !== newStatus) {
      updateTask({
        taskId: task.id,
        taskStatusId: newStatus,
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const onCreateTask = () => {
    openDrawer({
      drawerType: DrawerType.CREATE_TASK,
      component: <CreateTaskDrawerComponent />,
    });
  };

  const onFilterPriorityChange = (priority: TaskPriorityEnum | null) => {
    setFilteredPriority(priority);
  };

  const onFilterRoomChange = (room: IRoomIdName | null) => {
    setFilteredRoom(room);
  };

  const onCreateTaskStatusPopinClose = useCallback(() => {
    setIsCreateTaskStatusPopinVisible(false);
  }, []);

  const onCreateTaskStatusPopinOpen = useCallback(() => {
    setIsCreateTaskStatusPopinVisible(true);
  }, []);

  return {
    tasks,
    taskStatuses,
    activeTask,
    activeColumn,
    onTaskHover,
    onDragStart,
    onDragEnd,
    sensors,
    onCreateTask,
    filteredPriority,
    onFilterPriorityChange,
    filteredRoom,
    onFilterRoomChange,
    onDragOver,
    onCreateTaskStatusPopinClose,
    onCreateTaskStatusPopinOpen,
    isCreateTaskStatusPopinVisible,
    createTaskStatusPopinKey,
    filteredTags,
    onFilterTagChange,
  };
};
