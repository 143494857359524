import { type IChannel } from "@/api/client";
import { RoomAvatarComponent } from "@/components/shared/room/RoomAvatarComponent";
import { type FunctionComponent } from "react";
interface IChannelOptionComponentProps {
  channel: IChannel;
}
export const ChannelOptionComponent: FunctionComponent<IChannelOptionComponentProps> = ({ channel }) => {
  return (
    <span className="flex items-center">
      <span>
        <span className="font-medium">#{channel.name}</span>
        {" • "}
      </span>
      <div className="mx-2">
        <RoomAvatarComponent roomId={channel.room.id} className="h-4 w-4" />
      </div>
      <span> {channel.room.name}</span>
    </span>
  );
};
