import { eventNames } from "@/constants/eventNames";
import { QueryKeys } from "@/constants/queryKeys";
import { EmailThreadContext } from "@/contexts/EmailThreadContextProvider";
import { RoomContext } from "@/contexts/RoomContext";
import { useSendMailMutation } from "@/hooks/mutations/emails/useSendEmailMutation";
import { useFileUpload } from "@/hooks/shared/useFileUpload";
import { dispatchCustomWindowEvent } from "@/utils/utilities";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface IMailDetail {
  html: string;
  text: string;
}
export const useSendMail = (contextType: string, contextId: string) => {
  const { mutate } = useSendMailMutation();
  const { thread, to, cc, replyToMailId } = useContext(EmailThreadContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toEmails = useMemo(() => to?.map((toElement) => toElement.email) ?? [], [to]);
  const ccEmails = useMemo(() => cc?.map((ccElement) => ccElement.email) ?? [], [cc]);
  const roomContext = useContext(RoomContext);

  const roomId = useMemo(() => {
    if (roomContext?.roomResponse == null) return;
    return roomContext.roomResponse.room.id;
  }, [roomContext?.roomResponse?.room.id]);
  // We are using events because quill seams to have issues with reactivity
  const sendMail = useCallback((html?: string, text?: string) => {
    dispatchCustomWindowEvent("sendEmail", { html, text });
  }, []);

  const { files, areFilesUploading } = useFileUpload(contextType, contextId);

  const sendMailHandler = useCallback(
    (event: CustomEvent<IMailDetail>) => {
      if (thread == null || replyToMailId == null) return;
      const { html, text } = event.detail;

      if (files.length > 0 && areFilesUploading) {
        toast.error(t("roomPage.tabs.room.conversation.message.toasts.waitForUploadError"));
        return null;
      }

      mutate(
        {
          replyToMailId,
          text,
          html,
          subject: thread?.lastEmail?.subject ?? "",
          to: toEmails,
          cc: ccEmails,
          files: files.map((file) => ({ id: file.id })),
        },
        {
          onSuccess: () => {
            toast.success(t("roomPage.tabs.mails.success"));
            dispatchCustomWindowEvent(eventNames.RESET_REPLY, thread.id);
            dispatchCustomWindowEvent(eventNames.RESET_MESSAGE_FILE, contextId);

            void queryClient.invalidateQueries({ queryKey: [QueryKeys.EMAIL_THREADS, roomId] });
            void queryClient.invalidateQueries({ queryKey: [QueryKeys.EMAIL_THREAD, QueryKeys.EMAILS, thread.id] });
          },
        },
      );
    },
    [toEmails, ccEmails, replyToMailId, thread, roomId, files, areFilesUploading],
  );

  useEffect(() => {
    window.addEventListener("sendEmail", sendMailHandler as EventListener);

    return () => {
      window.removeEventListener("sendEmail", sendMailHandler as EventListener);
    };
  }, [toEmails, ccEmails, replyToMailId, files, areFilesUploading]);

  return {
    sendMail,
  };
};
