import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { eventNames } from "@/constants/eventNames";
import { dispatchCustomWindowEvent } from "@/utils/utilities";
import { useCallback, useContext } from "react";

export const useMessageThread = () => {
  const { closeAllDrawers } = useContext(DrawerContext);

  const openThread = useCallback((messageId: string) => {
    closeAllDrawers();
    dispatchCustomWindowEvent(eventNames.OPEN_THREAD, { messageId });
  }, []);

  return {
    openThread,
  };
};
