import { FileTableHeaderContextActionsComponent } from "@/components/roomPage/tabs/files/FileTableHeaderContextActionsComponent";
import { useTranslation } from "react-i18next";

interface IFileTableHeaderComponentProps {
  isInRoom: boolean;
  selectAll: boolean;
  handleSelectAll: (value: boolean) => void;
  selectedItems: string[];
}
export const FileTableHeaderComponent = ({ isInRoom, selectAll = false, handleSelectAll, selectedItems }: IFileTableHeaderComponentProps) => {
  const { t } = useTranslation();

  const handleSelectAllChange = () => {
    handleSelectAll(!selectAll);
  };
  return (
    <tr className="relative border-[red] bg-white dark:bg-dark-900">
      <th
        className={`flex shrink-0 items-center py-3 pl-7 text-left text-xs font-normal text-zinc-800 dark:text-dark-300 ${
          isInRoom ? "px-layout" : ""
        }`}
      >
        <input id="select-all-file" type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
        <label htmlFor="select-all-file" className="ml-4 cursor-pointer select-none">
          {selectAll ? t("files.selectNone") : t("files.selectAll")}
        </label>
        <FileTableHeaderContextActionsComponent selectedItems={selectedItems} />
      </th>
      {!isInRoom && (
        <th className="py-3 text-left text-sm font-normal text-zinc-800 dark:text-dark-300">{t("homePage.tabs.files.filters.table.header.room")}</th>
      )}
      <th className="py-3 text-left text-sm font-normal text-zinc-800 dark:text-dark-300">
        {t("homePage.tabs.files.filters.table.header.channelOrDm")}
      </th>
      <th className="py-3 text-left text-sm font-normal text-zinc-800 dark:text-dark-300">{t("homePage.tabs.files.filters.table.header.addedBy")}</th>
      <th className="py-3 text-left text-sm font-normal text-zinc-800 dark:text-dark-300">{t("homePage.tabs.files.filters.table.header.date")}</th>
      <th className="absolute bottom-0 left-0 z-30 h-px w-full bg-zinc-200 p-0 dark:bg-dark-700"></th>
      <th className="absolute left-0 top-0 z-30 h-px w-full bg-zinc-200 p-0 dark:bg-dark-700"></th>
    </tr>
  );
};
