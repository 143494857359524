import { type IInvitation, type IRoom, type IRoomMember } from "@/api/client";
import { useAdminProjectRoomMemberTab } from "@/components/homePage/modals/room/tabs/useAdminProjectRoomMemberTab";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { PendingInvitationComponent } from "@/components/shared/invitation/PendingInvitationComponent";
import { InviteOrganisationMemberComponent } from "@/components/shared/members/InviteOrganisationMemberComponent";
import { InviteRoomMemberComponent } from "@/components/shared/members/InviteRoomMemberComponent";
import { RoomMemberComponent } from "@/components/shared/members/RoomMemberComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { BulkInviteContext } from "@/contexts/BulkInviteContextProvider";
import { useBulkInvite } from "@/hooks/shared/bulkInvite/useBulkInvite";
import { useContext, type FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";

interface AdminProjectRoomMemberTabProps {
  room: IRoom;
  member: IRoomMember;
}

export const AdminProjectRoomMemberTab: FunctionComponent<AdminProjectRoomMemberTabProps> = ({ room, member }) => {
  const { t, i18n } = useTranslation();

  const { mails } = useContext(BulkInviteContext);
  const {
    handleBatchInvite,
    invitations,
    members,
    filteredMembers,
    mailsAlreadyInvited,
    filteredOrganisationMembers,
    invitedFilteredMembers,
    isAlreadyInvited,
    search,
    updateSearch,
    onlyGuests,
  } = useAdminProjectRoomMemberTab(room.id);

  const { handlePaste } = useBulkInvite(mailsAlreadyInvited);

  return (
    <ModalTabComponent hasPadding={false}>
      <>
        <div className="mb-2 px-6 py-4 pb-0">
          <TextInputComponent
            value={search}
            onChange={(e) => {
              updateSearch(e.target.value);
            }}
            onPaste={(e) => {
              handlePaste(e);
              updateSearch("");
            }}
            placeholder={t("createRoomModal.tabs.members.inputPlaceholder")}
          />
        </div>
        <div>
          <div className="flex flex-col">
            {mails.length > 0 ? (
              <div className="order-first">
                <h3 className="mb-1 mt-2 flex w-full items-center px-6 text-sm font-medium text-zinc-500 dark:text-dark-300 ">
                  <Trans
                    i18nKey="roomModal.members.organisation.pastedInvites.subtitle"
                    i18n={i18n}
                    values={{ count: mails.length }}
                    components={{ active: <span className="ml-1 text-sky-600" /> }}
                  />

                  <ButtonComponent
                    status="secondary"
                    size="sm"
                    className="ml-auto border-0 font-[600] dark:bg-dark-700 dark:text-dark-300 dark:hover:bg-dark-600"
                    onClick={async () => {
                      await handleBatchInvite(mails);
                    }}
                  >
                    {t("roomModal.members.organisation.pastedInvites.inviteButton", { count: mails.length })}
                  </ButtonComponent>
                </h3>
                {mails.map((email) => (
                  <InviteOrganisationMemberComponent key={email} email={email} isInBulk />
                ))}
              </div>
            ) : null}
            {!isAlreadyInvited && search !== "" ? (
              <div className="order-1">
                <h3 className="mb-1 mt-4 flex items-center px-6 text-sm font-medium text-zinc-500 dark:text-dark-300">
                  {t("createRoomModal.tabs.members.inviteNewMember")}
                </h3>
                <section>
                  <InviteRoomMemberComponent email={search?.trim()} roomId={room.id} />
                </section>
              </div>
            ) : null}

            {search !== "" && search != null ? (
              invitedFilteredMembers.length > 0 ? (
                <div className={search === "" ? "order-3" : "order-2"}>
                  <h3 className="mb-1 mt-4 flex items-center px-6 text-sm font-medium text-zinc-500 dark:text-dark-300">
                    <Trans
                      i18nKey="createRoomModal.tabs.members.invitedGuests"
                      i18n={i18n}
                      values={{ count: invitedFilteredMembers.length }}
                      components={{ active: <span className="ml-1 text-sky-600" /> }}
                    />
                  </h3>
                  <section>
                    {invitedFilteredMembers.map((member) => (
                      <InviteRoomMemberComponent email={member.email} key={member.email} roomId={room.id} member={member} />
                    ))}
                  </section>
                </div>
              ) : null
            ) : null}
            {filteredOrganisationMembers.length > 0 ? (
              <div className={search === "" ? "order-2" : "order-3"}>
                <h3 className="mb-1 mt-4 flex items-center px-6 text-sm font-medium text-zinc-500 dark:text-dark-300">
                  <Trans
                    i18nKey="createRoomModal.tabs.members.inviteOrgMember"
                    i18n={i18n}
                    values={{ count: filteredOrganisationMembers.length }}
                    components={{ active: <span className="ml-1 text-sky-600" /> }}
                  />
                </h3>
                <section>
                  {filteredOrganisationMembers.map((organisationMember) => (
                    <InviteRoomMemberComponent
                      key={organisationMember.id}
                      email={organisationMember.email}
                      member={organisationMember}
                      roomId={room.id}
                    />
                  ))}
                </section>
              </div>
            ) : null}
            {invitations != null && invitations.length > 0 ? (
              <div className={search === "" ? "order-1" : "order-6"}>
                <h3 className="mb-1 mt-4 flex items-center px-6 text-sm font-medium text-zinc-500 dark:text-dark-300 ">
                  <Trans
                    i18nKey="createRoomModal.tabs.members.invites"
                    i18n={i18n}
                    values={{ count: invitations.length }}
                    components={{ active: <span className="ml-1 text-sky-600" /> }}
                  />
                </h3>
                {invitations.map((invitation: IInvitation) => {
                  return <PendingInvitationComponent invitation={invitation} scope="room" key={invitation.id} />;
                })}
              </div>
            ) : null}

            <div className={search === "" ? "order-1" : "order-5"}>
              {members != null ? (
                <h3 className="mb-1 mt-4 flex items-center px-6 text-sm font-medium text-zinc-500 dark:text-dark-300">
                  <Trans
                    i18nKey="createRoomModal.tabs.members.inRoom"
                    i18n={i18n}
                    values={{ count: filteredMembers.length }}
                    components={{ active: <span className="ml-1 text-sky-600" /> }}
                  />
                </h3>
              ) : null}
              {members != null
                ? filteredMembers.map((roomMember) => (
                    <RoomMemberComponent
                      member={roomMember}
                      key={roomMember.userId}
                      isLast={members.length === 1}
                      myRole={member.role}
                      hasGuest={onlyGuests}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
      </>
    </ModalTabComponent>
  );
};
