import { type DeltaContent, type IMessage } from "@/api/client";
import { eventNames } from "@/constants/eventNames";
import { ConversationContext } from "@/contexts/ConversationContext";
import { useMessageNavigation } from "@/hooks/shared/useMessageNavigation";
import { type IRichTextEditorRef } from "@/interfaces/richTextEditor";
import { getTypeOfFileFromExtension, isDeltaContentEmpty, orderById } from "@/utils/utilities";
import { differenceInMinutes } from "date-fns";
import { useCallback, useContext, useMemo, useRef, useState, type KeyboardEventHandler, type MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

export const useMessage = ({
  isInThread = false,
  message,
  previousMessage,
  isComment = false,
}: {
  message?: IMessage;
  previousMessage?: IMessage;
  isInThread?: boolean;
  isComment?: boolean;
}) => {
  const { member } = useContext(ConversationContext);
  const { t } = useTranslation();
  const { navigateToContext } = useMessageNavigation();
  const [deltaBeforeEditing, setDeltaBeforeEditing] = useState<DeltaContent | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);

  const editorRef = useRef<IRichTextEditorRef>(null);
  const messageRef = useRef<HTMLDivElement>(null);

  const createAtDate = new Date(message?.createdAt ?? "");
  const previousMessageCreatedAtDate = new Date(previousMessage?.createdAt ?? "");
  const differenceInMinutesBetweenMessages = differenceInMinutes(createAtDate, previousMessageCreatedAtDate);
  const shouldDisplayAuthor =
    previousMessage == null ||
    previousMessage.sender?.id !== message?.sender?.id ||
    previousMessage.type !== message?.type ||
    differenceInMinutesBetweenMessages >= 15;
  const isParentMessageInThread = isInThread && message?.nesting === "parent";
  const isFirstReplyInThread = message?.nesting === "child" && previousMessage?.nesting === "parent";
  const isMine = useMemo(() => message?.sender?.user.id === member?.user.id, [member]);
  const hasReaction = useMemo(() => message?.reactions != null && Object.keys(message.reactions).length > 0, [message]);
  const isContentEmpty = useMemo(() => {
    if (message?.content == null) return true;
    return isDeltaContentEmpty(message?.content);
  }, [message]);

  const shouldDisplayDateSeparator = useMemo(() => {
    if (isComment) {
      return false;
    }
    return (
      (previousMessage == null || previousMessageCreatedAtDate.toDateString() !== createAtDate.toDateString()) &&
      (!isInThread || !isParentMessageInThread)
    );
  }, [message, previousMessage, isComment, isInThread]);

  const images = useMemo(() => {
    if (message?.files == null || message.files.length === 0) return [];
    return orderById(message.files.filter((file) => getTypeOfFileFromExtension(file.extension) === "image"));
  }, [message]);

  const otherFiles = useMemo(() => {
    if (message?.files == null || message.files.length === 0) return [];
    return orderById(message.files.filter((file) => getTypeOfFileFromExtension(file.extension) !== "image"));
  }, [message]);

  // Callbacks
  const startEdition = useCallback(() => {
    if (editorRef?.current == null) return;
    setDeltaBeforeEditing(editorRef.current.getEditorContents() ?? null);
  }, [setDeltaBeforeEditing]);

  const cancelEdition = useCallback(
    (shouldReset = true) => {
      const event = new CustomEvent(eventNames.CLOSE_EDITION);
      if (shouldReset) window.dispatchEvent(event);
    },
    [editorRef.current],
  );

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const onEdit = useCallback(() => {
    if (isMine) startEdition();
  }, [startEdition]);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>((event) => {
    if (event.key === "Escape") {
      cancelEdition();
    }
  }, []);

  const handleSearchedMessageClick = useCallback(async (message: IMessage) => {
    await navigateToContext(message);
    const event = new CustomEvent(eventNames.CLOSE_SEARCH);
    window.dispatchEvent(event);
  }, []);

  const handleMouseEnterMessage = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
    if (event.target instanceof HTMLElement && event.target.classList.contains("edited")) return;
    setIsActionsMenuOpen(true);
  }, []);

  const onCloseActionsMenu = useCallback(() => {
    setIsActionsMenuOpen(false);
  }, []);

  const handleMouseLeaveMessage = useCallback<MouseEventHandler<HTMLDivElement>>((_event) => {
    onCloseActionsMenu();
  }, []);

  const location = useMemo(() => {
    if (message == null) return null;
    if (message?.conversation?.channel != null) {
      const { channel } = message.conversation;
      const roomName: string = channel.room?.name ?? "";
      const channelName: string = channel.name ?? "";

      return (
        <>
          {roomName} · <span className="text-sky-600">#{channelName}</span>
        </>
      );
    } else {
      return (
        <>
          {message?.sentFromRoom != null ? `${message.sentFromRoom?.name} · ` : ""}
          <span className="text-sky-600">{t("search.directMessages")}</span>
        </>
      );
    }
  }, [message]);

  const isEditing = useMemo(() => deltaBeforeEditing != null, [deltaBeforeEditing]);

  return {
    isEditing,
    deltaBeforeEditing,
    setDeltaBeforeEditing,
    isHovered,
    setIsHovered,
    isMine,
    shouldDisplayDateSeparator,
    shouldDisplayAuthor,
    handleMouseEnter,
    handleMouseLeave,
    onEdit,
    handleKeyDown,
    startEdition,
    cancelEdition,
    editorRef,
    messageRef,
    isFirstReplyInThread,
    isActionsMenuOpen,
    setIsActionsMenuOpen,
    hasReaction,
    isParentMessageInThread,
    isContentEmpty,
    images,
    otherFiles,
    location,
    handleSearchedMessageClick,
    handleMouseEnterMessage,
    handleMouseLeaveMessage,
    onCloseActionsMenu,
  };
};
