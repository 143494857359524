import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useEmailAttachmentQuery = (emailId: string, attachmentId: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.EMAIL_ATTACHMENT, emailId, attachmentId],
    queryFn: async () => {
      const response = await api.emails.getOneEmailAttachment(emailId, attachmentId);

      return response.data.data;
    },
  });

  return query;
};
