import { ModalHeaderComponent } from "@/components/shared/modal/ModalHeaderComponent";
import { useCallback, useEffect, useRef, useState } from "react";

export interface IModalTab {
  label: string;
  content: JSX.Element;
  id: string;
  disabled: boolean;
}
interface IModalLayoutProps {
  name: string;
  className?: string;
  title: JSX.Element | string;
  body?: JSX.Element | string;
  tabs?: IModalTab[];
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  size?: "auto" | "sm" | "md" | "lg" | "xl";
  defaultTab?: string;
  onTabChange?: (tab: IModalTab) => void;
  hideTabs?: boolean;
}

export const ModalLayoutComponent = ({
  name,
  title,
  body,
  onClick,
  tabs,
  size = "md",
  defaultTab,
  onTabChange,
  hideTabs = false,
  ...props
}: IModalLayoutProps): JSX.Element => {
  const modal = useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = useState<IModalTab>();

  const sizeClasses = {
    auto: `w-[38rem] h-auto`,
    sm: `w-[34rem] h-[22rem]`,
    md: `w-[38rem] h-2/3`,
    lg: `w-[44rem] h-2/3`,
    xl: `w-[48rem] h-2/3`,
  };

  const handleTabChange = useCallback(
    (tab: IModalTab | undefined) => {
      if (tab === undefined) return;
      setSelectedTab(tab);
      onTabChange?.(tab);
    },
    [tabs],
  );

  useEffect(() => {
    modal.current?.classList.remove("opacity-0");
    modal.current?.classList.add("opacity-100");
  }, [modal]);

  useEffect(() => {
    if (tabs !== undefined) {
      handleTabChange(tabs[0]);
      if (defaultTab === undefined) return;
      handleTabChange(tabs.find((tab) => tab.id === defaultTab));
    }
  }, []);

  return (
    <div
      ref={modal}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      {...props}
      className={`w-min overflow-hidden rounded-lg shadow-lg ring-1 ring-zinc-400 transition-all duration-300 dark:ring-dark-700 max-sm:h-full max-sm:w-full ${
        props.className ?? ""
      }
      ${sizeClasses[size]}
      `}
      onClick={onClick}
    >
      <div className="flex h-full flex-col rounded-lg bg-white shadow-lg dark:bg-dark-900">
        <ModalHeaderComponent title={title} hasTabs={tabs !== undefined} name={name} />
        {tabs !== undefined && tabs.length > 1 && !hideTabs && (
          <nav className="modal-tabs border-b bg-gray-100 px-6 dark:border-dark-700 dark:bg-dark-800">
            {tabs?.map((tab) => {
              const isSelected = selectedTab?.id === tab.id;
              return (
                <button
                  key={tab.id}
                  disabled={tab.disabled}
                  className={`-mb-px mr-6 text-sm font-medium dark:text-dark-300 ${
                    isSelected
                      ? "border-b-2 border-gray-500 text-zinc-900 dark:border-b-dark-500"
                      : tab.disabled
                      ? "text-zinc-300"
                      : "text-zinc-500 hover:text-zinc-900"
                  }`}
                  onClick={() => {
                    handleTabChange(tab);
                  }}
                >
                  {tab.label}
                </button>
              );
            })}
          </nav>
        )}
        <section className={`overflow-hidden ${sizeClasses[size]} h-full flex-1 max-sm:h-full max-sm:w-full`}>
          {selectedTab != null ? selectedTab.content : body}
        </section>
      </div>
    </div>
  );
};
