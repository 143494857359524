import { ConversationMemberStatus } from "@/api/client";
import { useConversationUtilities } from "@/components/roomPage/tabs/conversation/ConversationLists/useConversationUtilities";
import { ThreadComponent } from "@/components/roomPage/tabs/conversation/ThreadComponent";
import { ConversationHeaderComponent } from "@/components/shared/conversation/ConversationHeaderComponent";
import { ConversationWithEditorComponent } from "@/components/shared/conversation/ConversationWithEditorComponent";
import { eventNames } from "@/constants/eventNames";
import { ConversationContext } from "@/contexts/ConversationContext";
import { useConversationActiveMemberQuery } from "@/hooks/queries/conversations/useConversationActiveMemberQuery";
import { useConversationQuery } from "@/hooks/queries/conversations/useConversationQuery";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface IConversationTabComponentProps {
  conversationId: string;
  actionOnParticipants?: () => void;
  actionOnTitle?: () => void;
  title?: string;
  namePrefix?: string;
  participantTranslationKey: string;
  contextType: "dm" | "channel";
}

export const ConversationTabComponent = ({
  conversationId,
  actionOnParticipants = () => {},
  actionOnTitle = () => {},
  title,
  namePrefix,
  participantTranslationKey,
  contextType,
}: IConversationTabComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [threadId, setThreadId] = useState<string | null>(null);
  const { data: conversationMembers } = useConversationActiveMemberQuery(conversationId);
  const { data: conversation } = useConversationQuery(conversationId);
  const { parentMessageId } = useParams<{ parentMessageId: string }>();
  const { computeConversationTitleFromMemberList } = useConversationUtilities();

  const contextValue = useMemo(() => {
    return {
      ...conversation,
      members: conversationMembers,
    };
  }, [conversation?.conversation.id, conversationMembers]);

  const closeThread = useCallback((): void => {
    setThreadId(null);
  }, []);

  const openThread = useCallback((event: CustomEvent<{ messageId: string }>): void => {
    setThreadId(event.detail.messageId);
  }, []);

  useEffect(() => {
    window.addEventListener(eventNames.OPEN_THREAD, openThread as unknown as EventListener);
    window.addEventListener(eventNames.CLOSE_THREAD, closeThread as unknown as EventListener);
    return () => {
      window.removeEventListener(eventNames.OPEN_THREAD, openThread as unknown as EventListener);
      window.removeEventListener(eventNames.CLOSE_THREAD, closeThread as unknown as EventListener);
    };
  }, []);

  useEffect(() => {
    setThreadId(null);
  }, [conversationId]);

  useEffect(() => {
    if (parentMessageId != null) {
      setThreadId(parentMessageId);
    }
  }, [true, parentMessageId]);

  return (
    <>
      {conversation != null && conversationMembers != null && (
        <ConversationContext.Provider value={contextValue}>
          <div className="flex h-full w-full">
            <div className={`flex flex-1 flex-col overflow-auto bg-white dark:bg-dark-900 ${threadId !== "0" ? "w-1/2" : "w-full"}`}>
              <ConversationHeaderComponent
                title={title ?? computeConversationTitleFromMemberList(conversationMembers, true)}
                participantTitle={t(participantTranslationKey, { count: conversationMembers?.length ?? 1 })}
                actionOnParticipants={actionOnParticipants}
                actionOnTitle={actionOnTitle}
                namePrefix={namePrefix}
                contextType={contextType}
                participants={conversationMembers}
                conversationId={conversationId}
                isArchived={conversation.member.status === ConversationMemberStatus.Archived}
              />
              <div className="flex flex-1 overflow-auto">
                <ConversationWithEditorComponent
                  key={conversationId}
                  contextType="conversation"
                  contextId={conversationId}
                  isArchived={conversation.member.status === ConversationMemberStatus.Archived}
                />
                <ThreadComponent threadId={threadId} />
              </div>
            </div>
          </div>
        </ConversationContext.Provider>
      )}
    </>
  );
};
