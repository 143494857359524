import { type IMessage } from "@/api/client";
import { FileInMessageComponent } from "@/components/roomPage/tabs/conversation/message/FileInMessageComponent";
import { ForwardedMessageComponent } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/ForwardedMessageComponent";
import {
  MULTIPLE_IMAGES_IN_MESSAGE_WIDTH,
  ONLY_IMAGE_IN_MESSAGE_WIDTH,
} from "@/components/roomPage/tabs/conversation/message/ImageInMessageComponent";
import { MessageDateSeparatorComponent } from "@/components/roomPage/tabs/conversation/message/MessageDateSeparatorComponent";
import { MessageHeaderComponent } from "@/components/roomPage/tabs/conversation/message/MessageHeaderComponent";
import { MessageSeparatorComponent } from "@/components/roomPage/tabs/conversation/message/MessageSeparatorComponent";
import { MessageThreadInfoComponent } from "@/components/roomPage/tabs/conversation/message/MessageThreadInfoComponent";
import { useIsMessageHighlighted } from "@/components/roomPage/tabs/conversation/message/useIsMessageHighlighted";
import { useNewMessageIndicator } from "@/components/roomPage/tabs/conversation/message/useNewMessageIndicator";
import { MessageActionsMenuComponent } from "@/components/roomPage/tabs/conversation/MessageActionsMenuComponent";
import { ReactionListComponent } from "@/components/roomPage/tabs/conversation/ReactionListComponent";
import { useMessageActionsMenu } from "@/components/roomPage/tabs/conversation/useMessageActionsMenu";
import { EmojiPickerForReactionsComponent } from "@/components/shared/conversation/EmojiPickerForReactionsComponent";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { eventNames } from "@/constants/eventNames";
import { ConversationContext } from "@/contexts/ConversationContext";
import { useMessage } from "@/hooks/shared/useMessage";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import { useReaction } from "@/hooks/shared/useReaction";
import { useWindowDimensions } from "@/hooks/shared/useWindowDimensions";
import { format } from "date-fns";
import { type DeltaStatic } from "quill";
import { Fragment, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BsPinAngleFill as PinFillIcon } from "react-icons/bs";

export const MessageComponent = ({
  message,
  previousMessage,
  isInThread = false,
  isInPinBoard = false,
  isComment = false,
}: {
  message: IMessage;
  previousMessage?: IMessage;
  isInThread?: boolean;
  isInPinBoard?: boolean;
  isComment?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  // contexts
  const { preferences } = useContext(PreferencesContext);

  // hooks
  const { setIsEmojiPickerOpen, isEmojiPickerOpen, pickerPosition, openEmojiPicker } = useReaction();
  const { unpinMessage } = useMessageActionsMenu(message);
  const {
    isEditing,
    deltaBeforeEditing,
    setDeltaBeforeEditing,
    onEdit,
    shouldDisplayDateSeparator,
    shouldDisplayAuthor,
    isFirstReplyInThread,
    handleMouseEnter,
    handleMouseLeave,
    editorRef,
    messageRef,
    handleKeyDown,
    isActionsMenuOpen,
    hasReaction,
    isMine,
    isContentEmpty,
    images,
    otherFiles,
    location,
    handleMouseEnterMessage,
    handleMouseLeaveMessage,
    setIsActionsMenuOpen,
    onCloseActionsMenu,
  } = useMessage({ message, previousMessage, isInThread, isComment });
  const { shouldDisplayNewMessageIndicator } = useNewMessageIndicator(message, previousMessage, isMine);
  const { isHighlighted } = useIsMessageHighlighted(message);
  const conversationContext = useContext(ConversationContext);
  const dimensions = useWindowDimensions();
  const { isOpened: isDrawerOpened } = useContext(DrawerContext);

  useEffect(() => {
    const closeEditionHandler = () => {
      setDeltaBeforeEditing(null);
      editorRef?.current?.resetEditor(message.id);
      setIsActionsMenuOpen(false);
    };
    window.addEventListener(eventNames.CLOSE_EDITION, closeEditionHandler);
    return () => {
      window.removeEventListener(eventNames.CLOSE_EDITION, closeEditionHandler);
    };
  }, [message.id]);

  const messageContentStyle = useMemo(() => {
    if (images.length <= 0) {
      return {};
    }
    if (images.length > 1) {
      const TWO_COLUMNS_WIDTH = (MULTIPLE_IMAGES_IN_MESSAGE_WIDTH + 8) * 2 + 8;
      const ONE_COLUMN_WIDTH = MULTIPLE_IMAGES_IN_MESSAGE_WIDTH + 16;

      if (isDrawerOpened) {
        return { width: dimensions.width > 1250 ? TWO_COLUMNS_WIDTH : ONE_COLUMN_WIDTH };
      } else {
        return { width: dimensions.width > 850 ? TWO_COLUMNS_WIDTH : ONE_COLUMN_WIDTH };
      }
    } else {
      return { width: ONLY_IMAGE_IN_MESSAGE_WIDTH + 16 };
    }
  }, [images, dimensions, isDrawerOpened]);
  const createdAt = useMemo(() => new Date(message.createdAt), [message]);

  return (
    <Fragment key={message.id}>
      <div
        className={`${
          !shouldDisplayNewMessageIndicator || isComment ? "m-0 block h-0 overflow-hidden p-0 leading-[0] opacity-0" : "h-[32px] opacity-100"
        } `}
      >
        <MessageSeparatorComponent lineColor="#0284c7" textColor="#0284c7" label={t("roomPage.tabs.room.conversation.message.newMessages")} />
      </div>
      <div
        className="group/message message-wrapper relative mx-layout"
        onDoubleClick={onEdit}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleKeyDown}
      >
        {/* day separator */}
        {shouldDisplayDateSeparator && <MessageDateSeparatorComponent date={message.createdAt} />}
        {/* message */}
        <article
          className={`message mr-8 flex flex-col pb-0.5 ${shouldDisplayAuthor || isInPinBoard ? "pt-2" : ""} ${
            isInPinBoard || isComment ? "w-full" : " max-w-[75%] "
          }`}
        >
          {isInPinBoard ? (
            <div className="mb-2 flex items-center justify-between">
              <span className="text-[11px] font-normal text-gray-400">{location}</span>
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  unpinMessage();
                }}
              >
                <PinFillIcon className="m-1 h-3.5 w-3.5 text-sky-600 hover:opacity-75 dark:text-dark-300" />
              </button>
            </div>
          ) : null}
          <div className="flex">
            {(shouldDisplayAuthor || isFirstReplyInThread || isInPinBoard || isComment) && (
              <MessageHeaderComponent message={message} isInPinboard={isInPinBoard} isComment={isComment} />
            )}
          </div>
          <div className={`group flex ${isEditing ? "w-full" : "max-w-max"}`}>
            <span
              className={`mr-2 h-6 w-6 pt-2 text-2xs font-medium text-gray-400 opacity-0 ${
                !isComment && !isInPinBoard ? "group-hover:opacity-100" : ""
              } transition-opacity duration-500`}
              style={{ direction: "rtl" }}
            >
              <TooltipComponent content={format(createdAt, t("date.shortFormatWithTime"))} placement="right">
                <span className="h-6 w-6">{format(createdAt, t("date.timeFormat"))}</span>
              </TooltipComponent>
            </span>
            <div
              className="relative w-full"
              style={{ width: "calc(100% - 30px)" }}
              onMouseEnter={handleMouseEnterMessage}
              onMouseLeave={handleMouseLeaveMessage}
            >
              {message.deletedAt == null && !isEditing && isActionsMenuOpen && conversationContext.member?.status === "confirmed" && (
                <MessageActionsMenuComponent message={message} onEdit={onEdit} isInThread={isInThread} onCloseActionsMenu={onCloseActionsMenu} />
              )}
              <div
                ref={messageRef}
                className={`message-content relative flex flex-col rounded-md
                           border transition-all duration-200 dark:bg-dark-600
                          ${isHighlighted ? "border-sky-200 bg-sky-100" : "bg-zinc-100"}
                          ${isEditing ? "w-full" : "max-w-max group-hover:bg-zinc-200 dark:group-hover:bg-dark-500 dark:group-hover:text-dark-100"}
                          ${
                            message.pinned === true && !isEditing
                              ? "border-sky-500"
                              : "border-zinc-200/60 group-hover:border-zinc-300 dark:border-dark-700"
                          }
              `}
                style={messageContentStyle}
              >
                <div className="px-2 py-1.5">
                  {/* TODO: Remove the ts-ignore when the typing in the client is fixed */}
                  {/*  @ts-expect-error back typing is wrong */}
                  {message.deletedAt != null || message.content === "" ? (
                    <span className="text-[13px] text-zinc-400">
                      {/*  @ts-expect-error back typing is wrong  */}
                      {t(`roomPage.tabs.room.conversation.message.${message.content === "" ? "deletedMessage" : "deletedMessageByMe"}`)}
                    </span>
                  ) : (
                    <>
                      {message.forward != null && (
                        <ForwardedMessageComponent
                          className={`${otherFiles.length + images.length > 0 ? "pb-2" : ""}`}
                          showForward={message.forward.forwardType !== "reply"}
                          forward={message.forward}
                        />
                      )}
                      <div>
                        {images.length > 0 ? (
                          <div className={`flex flex-row flex-wrap items-center justify-between ${isContentEmpty ? "" : "mb-1"}`}>
                            {images.map((image) => (
                              <FileInMessageComponent key={image.id} file={image} isOnlyImageInMessage={images.length === 1} />
                            ))}
                          </div>
                        ) : null}
                        {otherFiles.length > 0 && (
                          <div className={`[:not(:first-child)]:pt-40 flex flex-wrap items-center pb-1 ${isContentEmpty ? "" : "mb-1"}`}>
                            {otherFiles.map((file) => (
                              <FileInMessageComponent key={file.id} file={file} />
                            ))}
                          </div>
                        )}
                      </div>
                      <div className={!isEditing && isContentEmpty ? "hidden" : message.forward != null ? "pt-2" : ""}>
                        {message.contentEditedAt != null ? (
                          <div className="group/edited absolute -right-2 bottom-1.5 z-10 h-6 select-none pt-2 text-2xs font-medium text-gray-400 opacity-100 transition-opacity duration-500">
                            <div className="relative">
                              <span className="edited absolute text-left opacity-100 transition-all duration-500 group-hover/edited:opacity-0">
                                {t("roomPage.tabs.room.conversation.message.contentEdited")}
                              </span>
                              <span className="edited absolute whitespace-nowrap text-left opacity-0 transition-all duration-500  group-hover/edited:opacity-100">
                                {format(new Date(message.contentEditedAt), t("date.shortFormatWithTime"))}
                              </span>
                            </div>
                          </div>
                        ) : null}
                        <RichTextEditorComponent
                          contextType="editedMessage"
                          contextId={message.id}
                          isEditing={isEditing}
                          deltaBeforeEditing={deltaBeforeEditing}
                          isMessagePreview={true}
                          defaultValue={message.content as DeltaStatic}
                          ref={editorRef}
                          messageId={message.id}
                        />
                      </div>
                    </>
                  )}
                </div>
                {hasReaction && <ReactionListComponent openEmojiPicker={openEmojiPicker} message={message} />}
                {message.thread != null && message.thread.messages.count > 0 && !isInThread && !isInPinBoard && (
                  <MessageThreadInfoComponent thread={message.thread} messageId={message.id} hasReaction={hasReaction} />
                )}
              </div>
              {isEmojiPickerOpen && (
                <EmojiPickerForReactionsComponent
                  message={message}
                  position={pickerPosition}
                  isDark={preferences.darkMode === true}
                  closeEmojiPicker={() => {
                    setIsEmojiPickerOpen(false);
                  }}
                />
              )}
            </div>
          </div>
        </article>
        {message.nesting === "parent" && isInThread && (
          <MessageSeparatorComponent
            label={t("roomPage.tabs.room.conversation.thread.reply", { count: message.thread?.messages.count })}
            position="right"
          />
        )}
      </div>
    </Fragment>
  );
};
