import { type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface ListOfTranslatedKeysProps extends HTMLAttributes<HTMLDivElement> {
  start?: number;
  keyString: string;
  // TODO refacto whole component
  params?: Record<string, unknown>;
  showBullet?: boolean;
}

export const ListOfTranslatedKeysComponent = ({ start = 1, keyString, showBullet = true, params = {} }: ListOfTranslatedKeysProps): JSX.Element => {
  const { t } = useTranslation();
  const translationResult = t(keyString, { returnObjects: true, ...params });

  let itemsToRender: string[] = [];

  if (Array.isArray(translationResult)) {
    itemsToRender = translationResult as string[];
  } else if (typeof translationResult === "object") {
    itemsToRender = Object.values(translationResult);
  } else {
    itemsToRender = [translationResult];
  }

  const filteredItems = itemsToRender.slice(start - 1);

  if (filteredItems.length === 1 && !showBullet) {
    return <p className="mb-2 text-sm font-[300]">{filteredItems[0]}</p>;
  }
  return (
    <>
      {filteredItems.map((item, index) =>
        showBullet ? (
          <li key={index} className="list-inside list-disc text-sm font-[300] text-zinc-400">
            {item}
          </li>
        ) : (
          <p key={index} className="mb-2 text-sm font-[300]">
            {item}
          </p>
        ),
      )}
    </>
  );
};
